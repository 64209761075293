import React, { useEffect, useState } from "react";
import StyledLeftPanel from "./styled";
import { useNavigate } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID, vaticAxiosGet } from "../../../utils/axios_helper";
import { useDispatch, useSelector } from "react-redux";
import { set } from "services/reducer/home";
import {
  set as audienceSet,
  unset as audienceUnset,
} from "services/reducer/audience";
import {
  set as estimateSet,
  unset as estimateUnset,
} from "services/reducer/audience";
import { home as homeSelector } from "services/selector";
import homeIcon from "assets/left-panel/Home_fill.svg";
import connectIcon from "assets/left-panel/Chat_plus.svg";
import createIcon from "assets/left-panel/Pipe.svg";
import calibrateIcon from "assets/left-panel/Compas_mini.svg";
import audienceIcon from "assets/left-panel/User.svg";
import campaignIcon from "assets/left-panel/marketing (1) 4.svg";
import reportIcon from "assets/left-panel/Chart.svg";
import billingIcon from "assets/left-panel/Paper.svg";

interface LeftPanelInterface {
  heading: String[];
  setHeading: (val: String[]) => void;
  isExtended: boolean;
  activeTab: number;
  setActiveTab: (val: number) => void;
}

interface CountryList {
  name: string;
  short_name: string;
  db: string;
}

const LeftPanel: React.FunctionComponent<LeftPanelInterface> = ({
  isExtended,
  heading,
  setHeading,
  activeTab,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState<Array<CountryList>>([]);
  const dispatch = useDispatch();
  const home = useSelector(homeSelector);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/country-list");
      if (res && res.data) {
        setCountries(res.data);
      } else {
        setCountries([]);
      }
    };
    load();
  }, []);

  const handleCountryChange = (e: any) => {
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].db === e.target.value) {
        dispatch(
          set({
            countryDb: e.target.value.toLowerCase(),
            country: countries[i].name,
          })
        );
        dispatch(
          audienceSet({
            t_region: countries[i].name.toLocaleLowerCase(),
          })
        );
        dispatch(
          estimateSet({
            t_region: countries[i].name.toLocaleLowerCase(),
          })
        );
      }
    }
    window.location.reload();
  };
  return (
    <StyledLeftPanel
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="menu-box">
        <div className="region-ctn">
          <div className="region-h">Region</div>
          <div className="region-box">
            {/* <div className="region-text">{isExtended ? "INDIA" : "IN"}</div> */}
            <select className="custom-select" onChange={handleCountryChange}>
              {countries.map((val, ind) => (
                <option
                  className="region-text"
                  value={val.db}
                  selected={
                    val.name.toLowerCase() == home.country.toLocaleLowerCase()
                  }
                  key={ind}
                >
                  {isHovered ? val.name : val.short_name}
                </option>
              ))}
            </select>
            {/* <img src={downArrow} alt="|" className="region-arrow" /> */}
          </div>
        </div>
        <div
          className={`menu-row ${heading[0] === "Home" ? "active_tab" : ""}`}
          onClick={() => {
            // setHeading(['Home']); setActiveTab(1);
            navigate("/dashboard/home");
          }}
        >
            <img src={homeIcon} alt="home" className="icon"/>
          {isExtended && (
            <div
              className={`d-none ${
                heading[0] === "Home" ? "icon-text-active" : "icon-text"
              }`}
            >
              Home
            </div>
          )}
        </div>

        <div
            className={`menu-row ${heading[0] === "Connect" ? "active_tab" : ""}`}
            onClick={() => {
              // setHeading(['Platforms']); setActiveTab(4);
              navigate("/dashboard/platform");
            }}
        >
          <img src={connectIcon} alt="home" className="icon"/>
          {isExtended && (
              <div
                  className={`d-none ${
                      heading[0] === "Connect" ? "icon-text-active" : "icon-text"
                  }`}
              >
                Connect
              </div>
          )}
        </div>

        <div
            className={`menu-row ${heading[0] === "Create" ? "active_tab" : ""}`}
            onClick={() => {
              // setHeading(['Audience']); setActiveTab(2);
              dispatch(estimateUnset());
              navigate("/dashboard/estimate");
            }}
        >
          <img src={createIcon} alt="home" className="icon"/>
          {isExtended && (
              <div
                  className={`d-none ${
                      heading[0] === "Create" ? "icon-text-active" : "icon-text"
                  }`}
              >
                Create
              </div>
          )}
        </div>

        <div
            className={`menu-row ${
                heading[0] === "Calibrate" ? "active_tab" : ""
            }`}
            onClick={() => {
              // setHeading(['Audience']); setActiveTab(2);
              dispatch(estimateUnset());
              navigate("/dashboard/explore");
            }}
        >
          <img src={calibrateIcon} alt="home" className="icon"/>
          {isExtended && (
              <div
                  className={`d-none ${
                      heading[0] === "Calibrate" ? "icon-text-active" : "icon-text"
                  }`}
              >
                Calibrate
              </div>
          )}
        </div>

        <div
            className={`menu-row ${
                heading[0] === "Audience" ? "active_tab" : ""
            }`}
            onClick={() => {
              // setHeading(['Audience']); setActiveTab(2);
              dispatch(audienceUnset());
              navigate("/dashboard/audience");
            }}
        >
          <img src={audienceIcon} alt="home" className="icon"/>
          {isExtended && (
              <div
                  className={`d-none ${
                      heading[0] === "Audience" ? "icon-text-active" : "icon-text"
                  }`}
              >
                Audience
              </div>
          )}
        </div>

        <div
            className={`menu-row ${
                heading[0] === "Campaign" ? "active_tab" : ""
            }`}
            onClick={() => {
              // setHeading(['Campaign']); setActiveTab(3);
              navigate("/dashboard/campaign");
            }}
        >
          <img src={campaignIcon} alt="home" className="icon"/>
          {isExtended && (
              <div
                  className={`d-none ${
                      heading[0] === "Campaign" ? "icon-text-active" : "icon-text"
                  }`}
              >
                Campaign
              </div>
          )}
        </div>

        <div
            className={`menu-row ${heading[0] === "Report" ? "active_tab" : ""}`}
            onClick={() => {
              navigate("/dashboard/report");
            }}
        >
          <img src={reportIcon} alt="home" className="icon"/>
          {isExtended && (
              <div
                  className={`d-none ${
                      heading[0] === "Report" ? "icon-text-active" : "icon-text"
                  }`}
              >
                Reports
              </div>
          )}
        </div>

        <div
            className={`menu-row ${heading[0] === "Billing" ? "active_tab" : ""}`}
            onClick={() => {
              navigate("/dashboard/billing");
            }}
        >
          <img src={billingIcon} alt="home" className="icon"/>
          {isExtended && (
              <div
                  className={`d-none ${
                      heading[0] === "Billing" ? "icon-text-active" : "icon-text"
                  }`}
              >
                Billing
              </div>
          )}
        </div>
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledLeftPanel>
  );
};

export default LeftPanel;
