import React, { useEffect, useState } from "react";
import FirstLoginStyled from "./styled";
import Header from "../Header";
import ai from "assets/ai1.svg";
import diy from "assets/diy1.svg";
import urls from "assets/website1.svg";
import { useDispatch, useSelector } from "react-redux";
import { estimate as estimateSelector, login, home } from "services/selector";
import { set } from "services/reducer/estimate";
import { useNavigate } from "react-router-dom";

const FirstLogin: React.FunctionComponent = () => {
  const [moveUp, setMoveUp] = useState(false);
  const loginInfo = useSelector(login);
  const nav = useNavigate();
  const dispatch = useDispatch();
  const estimate = useSelector(estimateSelector);

  const [error, setError] = useState("");
  const { country } = useSelector(home);

  const handleNext = (method: number) => {
    if (!estimate.estimateName) {
      setError("Please enter a valid estimate name");
      return;
    }
    dispatch(set({ activeTab: 1 }));
    nav(`/dashboard/estimate/create/define?method=${method}`);
    window.location.reload();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMoveUp(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <FirstLoginStyled moveUp={moveUp}>
      <div className="fl-ctn">
        <Header />
        <div className={moveUp ? "main-intro move-up" : "main-intro m1"}>
          <div className="inner-ctn">
            <div className={`welcome ${moveUp ? "" : "white-font"}`}>
              Welcome {loginInfo.first_name}
              <br /> to VaticAI!
            </div>
            <div className={`second-desc ${moveUp ? "" : "white-font"}`}>
              We’re excited to have you on board. Get ready to transform your
              digital advertising with AI-powered precision, real-time insights,
              and personalized targeting. Dive in, explore, and unlock the full
              potential of your campaigns.
            </div>
            <div className={`third-desc ${moveUp ? "" : "white-font"}`}>
              To start with, please select the method you want to choose to give
              a boost to your ads and target real audiences
            </div>
          </div>
        </div>
        {!moveUp ? (
          ""
        ) : (
          <div>
            <div className="aud-name">Name your estimate </div>
            <div className={"aud-name-inp"}>
              <input
                type="text"
                className={error ? "aud-name-input error" : "aud-name-input"}
                value={estimate.estimateName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(set({ estimateName: e.target.value }));
                  setError("");
                }}
              />
            </div>
            {error && <div className="error-msg">{error}</div>}
          </div>
        )}
        {!moveUp ? (
          " "
        ) : (
          <div className="create-methods-ctn">
            <div className="method-ctn" onClick={() => handleNext(1)}>
              <div className="method-icon-ctn">
                <img className="method-icon" src={ai}></img>
              </div>

              <div>
                <div className="method-title">AI</div>
                <div className="method-desc">
                Use this approach to enter your product or service and get your keyword suggestions back from our AI service.
		</div>
              </div>
            </div>
            <div onClick={() => handleNext(2)} className="method-ctn">
              <div className="method-icon-ctn">
                <img className="method-icon" src={diy}></img>
              </div>

              <div>
                <div className="method-title">DIY</div>
                <div className="method-desc">
                Use this approach if you already have your list of keywords you simply want to enter them and build an audience. 
		</div>
              </div>
            </div>
            <div onClick={() => handleNext(3)} className="method-ctn">
              <div className="method-icon-ctn">
                <img className="method-icon" src={urls}></img>
              </div>

              <div>
                <div className="method-title">URLs</div>
                <div className="method-desc">
                Use this approach if you have a set of URLs that identify the traffic you would like to target with your advertising.
		</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </FirstLoginStyled>
  );
};

export default FirstLogin;
