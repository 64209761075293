import React from "react";
import StyledCampaign from "./styled";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";

import {audience} from "services/selector";
import useCampaign from "../../../../../hooks/useCampaign";

const Campaign = () => {
  const {
    campaigns,
    curr_audience_id: audience_id,
    aud_name: audienceName,
    selected_google_ad_acc: selectedGoogleAdAcc,
    selected_meta_ad_acc: selectedMetaAdAcc,
    selected_x_ad_acc: selectedXAdAcc,
    selected_tiktok_ad_acc: selectedTiktokAdAcc,
  } = useSelector(audience);
  const {selectAllCampaigns, toggleCampaignSelection, handleNext} = useCampaign();
  const navigate = useNavigate();

  return (
    <StyledCampaign>
      <div className="container">
        <b className="campaign-selection">Campaign Selection</b>

        <div className="connect-and-choose">
          Connect and choose platform for your created audience.
        </div>

        <div className="created-audience">Created audience:</div>

        <div className="banking-loans">{audienceName}</div>

        <div className="select-campaigns-2-container">
          <span>Select campaigns &nbsp;</span>
          <span className="span">
            {
              campaigns.filter(
                (i) =>
                  i.checked &&
                  (selectedGoogleAdAcc[i.acc_id] ||
                    selectedMetaAdAcc[i.acc_id] ||
                    selectedXAdAcc[i.acc_id] ||
                    selectedTiktokAdAcc[i.acc_id]
                  )
              ).length
            }
          </span>
        </div>

        <div className="keyword-table-ctn">
          <div className="campaign-table-h">
            <div className="campaign-table-select-all">
              <input
                type="checkbox"
                onClick={() => {
                  selectAllCampaigns();
                }}
              ></input>
              <div className="campaign-table-title">All</div>
            </div>
            <div className="campaign-table-title">Name</div>
            <div className="campaign-table-title">Client</div>
            <div className="campaign-table-title">Platforms</div>
          </div>
          <div className="hor-line"></div>
          <div className="campaign-table-body">
            {campaigns
              .filter(
                (i) =>
                  selectedGoogleAdAcc[i.acc_id] ||
                  selectedMetaAdAcc[i.acc_id] ||
                  selectedXAdAcc[i.acc_id] ||
                  selectedTiktokAdAcc[i.acc_id]
              )
              .map((item, index) => (
                <div
                  className={`campaign-table-row-even ${
                    index % 2 == 0 ? "little-dark-row" : ""
                  }`}
                  key={index}
                >
                  <div className="campaign-checkbox">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleCampaignSelection(item.id);
                      }}
                      checked={item.checked}
                    ></input>
                  </div>
                  <div className="campaign-name">{item.name}</div>
                  <div className="campaign-client">{item.client}</div>
                  <div className="campaign-platform">{item.platforms}</div>
                </div>
              ))}
          </div>
        </div>

        <div className="continue-btn-container">
          <div
            className="back-btn"
            onClick={() => {
              navigate("/dashboard/audience/create/platforms");
            }}
          >
            Back
          </div>
          <button
            className="continue-btn"
            type="submit"
            onClick={handleNext}
          >
            Continue
          </button>
        </div>
      </div>
    </StyledCampaign>
  );
};

export default Campaign;
