import React, { useEffect, useState } from "react";
import StyledMethod from "./styled";
import { set } from "services/reducer/estimate";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ai from "assets/ai1.svg";
import diy from "assets/diy1.svg";
import urls from "assets/website1.svg";
import { estimate as estimateSelector, login, home } from "services/selector";

interface Props {
  setHeading: (val: String[]) => void;
}

const Method: React.FunctionComponent<Props> = ({ setHeading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const estimate = useSelector(estimateSelector);
  const loginInfo = useSelector(login);

  const [error, setError] = useState("");
  const { country } = useSelector(home);

  const handleNext = (method: number) => {
    if (!estimate.estimateName) {
      setError("Please enter a valid estimate name");
      return;
    }
    dispatch(set({ activeTab: 1 }));
    navigate(`/dashboard/estimate/create/define?method=${method}`);
    window.location.reload();
  };

  useEffect(() => {
    setHeading([
      "Create",
      country.charAt(0) + country.substring(1).toLowerCase(),
      "Method",
    ]);
  }, []);

  return (
    <StyledMethod>
      <div className="fl-ctn">
        <div className="main-intro">
          <div className="inner-ctn">
            <div className={`welcome white-font"}`}>
              Welcome {loginInfo.first_name}
              <br /> to VaticAI!
            </div>
            <div className={`second-desc`}>
              We’re excited to have you on board. Get ready to transform your
              digital advertising with AI-powered precision, real-time insights,
              and personalized targeting. Dive in, explore, and unlock the full
              potential of your campaigns.
            </div>
            <div className={`third-desc`}>
              To start with, please select the method you want to choose to give
              a boost to your ads and target real audiences
            </div>
          </div>
        </div>

        <div className={"aud-name-inp"}>
          <div className="label">Name your estimate</div>
          <input
              type="text"
              className={error ? "aud-name-input error" : "aud-name-input"}
              value={estimate.estimateName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(set({estimateName: e.target.value}));
                setError("");
              }}
          />
        </div>
        {error && <div className="error-msg">{error}</div>}

        <div className="create-methods-ctn">
          <div className="method-ctn" onClick={() => handleNext(1)}>
            <div className="method-icon-ctn">
              <img className="method-icon" src={ai}></img>
            </div>

            <div className="method-detail-ctn">
              <div className="method-title">AI</div>
              <div className="method-desc">
              Use this approach to enter your product or service and get your keyword suggestions back from our AI service.
	      </div>
            </div>
          </div>
          <div className="method-ctn" onClick={() => handleNext(2)}>
            <div className="method-icon-ctn">
              <img className="method-icon" src={diy}></img>
            </div>

            <div className="method-detail-ctn">
              <div className="method-title">DIY</div>
              <div className="method-desc">
              Use this approach if you already have your list of keywords you simply want to enter them and build an audience. 
	      </div>
            </div>
          </div>
          <div className="method-ctn" onClick={() => handleNext(3)}>
            <div className="method-icon-ctn">
              <img className="method-icon" src={urls}></img>
            </div>

            <div className="method-detail-ctn">
              <div className="method-title">URLs</div>
              <div className="method-desc">
              Use this approach if you have a set of URLs that identify the traffic you would like to target with your advertising.
	      </div>
            </div>
          </div>
        </div>
      </div>
    </StyledMethod>
  );
};

export default Method;
