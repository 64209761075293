import styled from "styled-components";
import CommonStyles from "../../APP/common";
import colors from "../../APP/colors";
import theme, {dimension, fontSize} from "../../APP/theme";

const StyledCalibrating = styled(CommonStyles)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 20px;
    
    .heading-ctn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .count-ctn {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background: ${colors.primary[50] || '#EAF1FF'};
        padding: 16px 24px;
        border-radius: 5px;
        align-items: center;

        color: ${colors.black[700] || '#171717'};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h6};
        font-style: normal;
        font-weight: 700;
        line-height: ${theme.lineHeight};
    }
    
    .label {
        font-weight: 400;
    }
    
    .row {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
    
    .body-ctn {
        display: grid;
        grid-template-columns: 1.25fr 0.75fr;
    }
    
    .graph-ctn {
        display: flex;
        flex-direction: column;
        border-radius: 7px;
        border: 1px solid ${colors.white[700] || '#AEAEAE'};
        background: ${colors.white[50] || '#FFF'};
    }
    
    .graph-ctn-head {
        padding: 13px 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .init-legend {
        width: 32px;
        height: 12px;
        background: #58BE4E;
    }
    
    .current-legend {
        width: 32px;
        height: 12px;
        background: #5347FF;
    }
    
    .legend-ctn {
        display: flex;
        flex-direction: row;
        gap: 50px;
    }
    
    .legend-ctn-child {
        display: flex;
        flex-direction: row;
        gap: 11px;
        align-items: center;
    }
    
    .graph-ctn-body-relative {
        border-bottom: 1px solid ${colors.white[700] || '#AEAEAE'};
        border-top: 1px solid ${colors.white[700] || '#AEAEAE'};
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
    }
    
    .graph-ctn-body {
        padding: 13px 16px;
        width: calc(100% - 32px);
        height: calc(100% - 26px);
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        align-items: center;
        overflow-y: auto;
    }
    
    .graph-ctn-footer {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        padding: 12px 16px;
    }
    
    .option-ctn {
        display: flex;
        flex-direction: row;
        gap: 11px;
        align-items: center;
        cursor: pointer;
    }
    
    .options-text {
        color: ${colors.primary[600] || "#256CE8"};
    }
    
    .bulls-eye-ctn {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    
    .audience-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    
    .graph-ctn-options {
        width: 281px;
        height: calc(100% - 25px);
        overflow-y: auto;
        padding: 13px 15px;
        border-radius: 0 0 7px 7px;
        background: ${colors.white[50] || "#FFF"};
        box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.25);
        
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        gap: 21px;
    }
    
    .font-18 {
        font-size: ${fontSize.h6};
    }
    
    .close-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .dimension-ctn {
        display: flex;
        flex-direction: column;
    }
    
    .dimension-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 8px 0;
        border-bottom: 1px solid #000;
    }
    
    .checkbox {
        transform: scale(1.2);
        cursor: pointer;
    }
    
    .cursor-pointer {
        cursor: pointer;
    }

    .blurred {
        background: rgba(103, 103, 103, 0.60);
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .graph-ctn-body-relative {
            height: 400px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .body-ctn {
            grid-template-columns: 1fr;
        }

        .audience-btn {
            justify-content: center;
        }
        
        .graph-ctn-body-relative {
            height: 300px;
        }
    }

`;

export default StyledCalibrating;