import React, {useState} from 'react';
import {vaticAxiosPost} from "../utils/axios_helper";
import {toast} from "react-toastify";

const useBilling = () => {
    const [selectedPlan, setSelectedPlan] = useState(1);

    const handleCheckout = async (type: number) => {
        const response = await vaticAxiosPost('/api/v1/payment/url', {type: type});
        if (response.statusCode === 200) {
            window.location.href = response.data;
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    }

    return {selectedPlan, setSelectedPlan, handleCheckout};
}

export default useBilling;