import React, { useEffect, useState } from "react";
import StyledHomeCards, { SlidingContent } from "./styled";
import { useNavigate } from "react-router";

import tickIcon from "assets/home/Check_fill.png";
import leftArrow from "assets/home/left-arrow.png";
import rightArrow from "assets/home/right-arrow.png";

import Button from "../../../shared/components/Button";
import colors from "../../../APP/colors";
import { useSelector } from "react-redux";
import { home, login } from "../../../services/selector";
import ConnectPopup from "components/ConnectPopup";
import { vaticAxiosGet } from "utils/axios_helper";

interface HomeCardsProps {
  setHeading: (val: String[]) => void;
}

const HomeCards: React.FunctionComponent<HomeCardsProps> = ({ setHeading }) => {
  const navigate = useNavigate();
  const { country } = useSelector(home);
  const { user_id: userId } = useSelector(login);

  const [firstEstimate, setFirstEstimate] = useState(true);
  const [firstCallibrate, setFirstCallibrate] = useState(true);
  const [firstConnect, setFirstConnect] = useState(true);
  const [firstAudience, setFirstAudience] = useState(true);
  const [firstCampaign, setFirstCampaign] = useState(true);
  const [firstBilling, setFirstBilling] = useState(true);
  const [showConnect, setShowConnect] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const childCount = 7; // Number of child containers
  const itemsPerView = 3;
  const maxIndex = Math.ceil(childCount / itemsPerView) - 1;

  const handleNext = () => {
    setCurrentIndex((prev) => (prev < maxIndex ? prev + 1 : 0));
  };

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : maxIndex));
  };

  useEffect(() => {
    setHeading([
      "Home",
      country.charAt(0) + country.substring(1).toLowerCase(),
    ]);
    const load = async () => {
      try {
        const res = await vaticAxiosGet("/api/v1/me/home-progress", {
          user_id: userId,
        });

        if (res && res.data) {
          const data = res.data;
          setFirstEstimate(data.first_estimate);
          setFirstCallibrate(data.first_callibrate);
          setFirstConnect(data.first_connect);
          setFirstAudience(data.first_audience);
          setFirstCampaign(data.first_campaign);
          setFirstBilling(data.first_billing);
          setShowConnect(data.first_connect);
        }
      } catch (err) {
        console.log(err);
      }
    };

    load();
  }, []);

  return (
    <StyledHomeCards>
      <div>
        <div className="header-ctn">
          <div className="heading">Welcome to Homepage</div>
          <div className="header-desc">
            Welcome to your dashboard! 🎉 Take a tour of the platform, explore
            insights, manage your campaigns, and connect with your
            audience—everything you need is just a click away. Let’s get
            started!
          </div>
        </div>

        <div className="cart-ctn">
          <SlidingContent translateX={-currentIndex * 33}>
            <div className="cart">
              <div className="cart-desc-ctn">
                <div className="num-ctn">
                  <div className="num-item">01</div>
                  <div className="num-h">Create Audience</div>
                </div>
                <div className="cart-desc">
                  Create a target audience based on interests, behaviors and the websites people visit. This is where you find the right people at the right time.
                </div>
              </div>
              {firstEstimate ? (
                <div className="completed-ctn">
                  <Button
                    text="Get Started"
                    action={() => navigate("/dashboard/estimate/create")}
                  />
                </div>
              ) : (
                <div className="completed-ctn">
                  <img src={tickIcon} alt="" className="icon" />
                  <div className="completed-text">Completed</div>
                </div>
              )}
            </div>

            <div className="cart">
              <div className="cart-desc-ctn">
                <div className="num-ctn">
                  <div className="num-item">02</div>
                  <div className="num-h">Calibrate Audience</div>
                </div>
                <div className="cart-desc">
                  Refine and calibrate your target audience by filtering on affordability, recency, and frequency.  Distill your target audience and increase your performance. 
                </div>
              </div>
              {firstCallibrate ? (
                <div className="completed-ctn">
                  <Button
                    text="Get Started"
                    action={() => navigate("/dashboard/estimate/explore")}
                  />
                </div>
              ) : (
                <div className="completed-ctn">
                  <img src={tickIcon} alt="" className="icon" />
                  <div className="completed-text">Completed</div>
                </div>
              )}
            </div>

            <div className="cart">
              <div className="cart-desc-ctn">
                <div className="num-ctn">
                  <div className="num-item">03</div>
                  <div className="num-h">Connect</div>
                </div>
                <div className="cart-desc">
                  Connect this VectorMath account to your media buying and campaign management platforms for seamless audience upload and campaign monitoring. 
                </div>
              </div>
              {firstConnect ? (
                <div className="completed-ctn">
                  <Button
                    text="Get Started"
                    action={() => navigate("/dashboard/platform")}
                  />
                </div>
              ) : (
                <div className="completed-ctn">
                  <img src={tickIcon} alt="" className="icon" />
                  <div className="completed-text">Completed</div>
                </div>
              )}
            </div>

            <div className="cart">
              <div className="cart-desc-ctn">
                <div className="num-ctn">
                  <div className="num-item">04</div>
                  <div className="num-h">Audience</div>
                </div>
                <div className="cart-desc">
                  Edit, delete, or calibrate your audiences to fine-tune your campaigns
                </div>
              </div>
              {firstAudience ? (
                <div className="completed-ctn">
                  <Button
                    text="Get Started"
                    action={() => navigate("/dashboard/audience")}
                  />
                </div>
              ) : (
                <div className="completed-ctn">
                  <img src={tickIcon} alt="" className="icon" />
                  <div className="completed-text">Completed</div>
                </div>
              )}
            </div>

            <div className="cart">
              <div className="cart-desc-ctn">
                <div className="num-ctn">
                  <div className="num-item">05</div>
                  <div className="num-h">Campaigns</div>
                </div>
                <div className="cart-desc">
                  Track all your VectorMath audience campaigns across multiple media platforms in one place. 
                </div>
              </div>
              {firstCampaign ? (
                <div className="completed-ctn">
                  <Button
                    text="Get Started"
                    action={() => navigate("/dashboard/campaign")}
                  />
                </div>
              ) : (
                <div className="completed-ctn">
                  <img src={tickIcon} alt="" className="icon" />
                  <div className="completed-text">Completed</div>
                </div>
              )}
            </div>

            <div className="cart">
              <div className="cart-desc-ctn">
                <div className="num-ctn">
                  <div className="num-item">06</div>
                  <div className="num-h">Reports</div>
                </div>
                <div className="cart-desc">
                  Get your detailed performance statistics and the graphs you need to monitor the performance of all your VectorMath campaigns
                </div>
              </div>
              <div className="completed-ctn">
                <Button
                  text="Get Started"
                  action={() => navigate("/dashboard/report")}
                />
              </div>
            </div>

            <div className="cart">
              <div className="cart-desc-ctn">
                <div className="num-ctn">
                  <div className="num-item">07</div>
                  <div className="num-h">Billing</div>
                </div>
                <div className="cart-desc">
                  Add audience credits to your account and decide which pricing model makes sense for you.
                </div>
              </div>
              {firstBilling ? (
                <div className="completed-ctn">
                  <Button
                    text="Get Started"
                    action={() => navigate("/dashboard/billing")}
                  />
                </div>
              ) : (
                <div className="completed-ctn">
                  <img src={tickIcon} alt="" className="icon" />
                  <div className="completed-text">Completed</div>
                </div>
              )}
            </div>
          </SlidingContent>
        </div>

        <div className="btn-ctn">
          <Button
            text=""
            action={handlePrevious}
            border={`1px solid ${colors.primary[600]}`}
            height="49px"
            background="#FFF"
          >
            <img src={leftArrow} alt="previous" />
          </Button>
          <Button
            text=""
            action={handleNext}
            border={`1px solid ${colors.primary[600]}`}
            height="49px"
            background="#FFF"
          >
            <img src={rightArrow} alt="next" />
          </Button>
        </div>
      </div>
      {firstConnect ? (<ConnectPopup isOpen={showConnect} setIsOpen={setShowConnect} currPath="/dashboard/home" />):("")}
    </StyledHomeCards>
  );
};

export default HomeCards;
