import React, {useEffect, useRef, useState} from "react";
import { select } from 'd3-selection';
import { transition } from 'd3-transition';
import {group, easePoly, selectAll, pack, hierarchy, packEnclose, format} from 'd3'
import StyledProjection from "./styled";
import {IProjection} from "../../../interface/components";
import {TProjectionLabel} from "../../../interface/types";

const Projection: React.FunctionComponent<IProjection> = ({qscore, frequency, recency, intent, similarity, data, labelData}) => {

    const svgRef = useRef(null);
    var data_collection = data.map(item => {return {...item, audience_status: 'out'}});
    var margin = {
        top: 10,
        bottom: 10,
        left: 10,
        right:10
    }
    const width = 900 - margin.left - margin.right
    const svgheight = 600
    var height = svgheight - margin.top - margin.bottom;
    const vbox_left = 20

    var color_codes: {[key: number]: string} = {0:"#ff595e", 1:"#ffca3a", 2:"#8ac926", 3:"#1982c4", 4:"#6a4c93"}
    var cluster_headers: {[key: string | number]: string} = {"in":"Pick", "out":"Drop", 0:"Savvy City Dwellers", 1:"On the Brink", 2:"Rural Strugglers", 3:"Affluent Urbanites", 4:"Working-Class Families"}
    var c = { x: 400, y: 300},
        r = 130;
    let [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (svgRef.current) {
            const svg = select(svgRef.current);
            svg.attr(
                "viewBox",
                `${vbox_left} 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`
            );

            data_collection.forEach(function (arrayItem: any, v: any) {
                var pointinfo = getPointAtAngle(c, r + Math.floor(Math.random() * 130), v);
                var maidColor = color_codes[arrayItem['cluster']]

                const circle = svg.append('circle')
                    .attr('cx', c.x)
                    .attr('cy', c.y)
                    .attr('r', 5.5)
                    .attr('fill', maidColor)
                    .attr('id', "maid" + arrayItem.maid)
                    .style('stroke-opacity', 0.5)
                    .style('stroke', '#d9d9d9')
                    .transition()
                    .duration(500)
                    .ease(easePoly)
                    .delay(v * 0.25)
                    .attr('cx', pointinfo.x)
                    .attr('cy', pointinfo.y)
                    .attr('data-original-x', pointinfo.x)
                    .attr('data-original-y', pointinfo.y);
            });
        }
        if (similarity != 0 || recency != 0 || frequency != 0 || intent != 0 || qscore != 0) {
            audience();
        }
    }, []);

    var getPointAtAngle = function(center: any, radius: any, theta:any){
        // theta = 90 + theta;
        // return {
        //     x: center.x + radius * Math.sin(theta * Math.PI/180),
        //     y: center.y + radius * Math.cos(theta * Math.PI/180)
        // };
        return {
            x: center.x + radius * Math.sin(theta),
            y: center.y + radius * Math.cos(theta)
        };
    };

    function audience(){
        data_collection.forEach(function (arrayItem, v) {
            if(arrayItem['similarity'] >= similarity && arrayItem['frequency'] >= frequency &&
                arrayItem['recency'] >= recency && arrayItem['qscore'] >= qscore && arrayItem['intent'] >= intent) {
                arrayItem["audience_status"] = "in"
            }
            else{
                arrayItem["audience_status"] = "out"
            }
        });

        var cluster_pack_info: any = {};
        var rootkeys = ["root", "audience_status", "cluster"];
        const nestedtype = Array.from(
            group(
                data_collection,
                (d: any) => d[rootkeys[1]],
                (d: any) => d[rootkeys[2]]
            ),
            ([key, values]) => ({
                key,
                values: Array.from(values, ([subKey, subValues]) => ({
                    key: subKey,
                    values: subValues,
                })),
            })
        );
        nestedtype.forEach(function(k: any, v){
            var branch_values = k.values;
            cluster_pack_info[k.key] = {};
            branch_values.forEach(function(ke: any, va:any){
                cluster_pack_info[k.key][ke.key] = ke.values.length
            })
        })
        var packableAudience = {id: "root", values: nestedtype};
        var packChart = pack();
        packChart.size([900, 600]).padding(0);
        var root = hierarchy(packableAudience, function(d: any){return d.values})
            .sum(function(d){ return 1});
        var circlepackAudience = packChart(root).descendants()
        var posi: any = {}
        var circles: Array<{[key: string]: any}> = []
        circlepackAudience.filter(function(d: any) {return !d.children}).forEach(function(check: any, g){
            circles.push({'x':check.x, 'y':check.y, 'r':check.r})
            posi[check.data.maid] = [check.x, check.y, check.r, check.data.dimension_category, check.data.maid, check.data.audience_status]

        })
        selectAll('.enclosures').remove()
        selectAll('.clusterheader').remove()
        selectAll('.clustertitle').remove()
        const svg = select(svgRef.current);
        var descendantinfo = circlepackAudience.filter(function(d: any) {return d.children})
        descendantinfo.forEach(function(p: any, i) {
            if(i != 0){
                var enclosingCircleAttr = packEnclose([p])
                var cluster_perc = format(".0%")((p.children.length/data_collection.length))
                if ( p.depth == 1){
                    svg.append('text')
                        .attr('class', 'clustertitle')
                        .attr('x', p.x - 35)
                        .attr('y', p.y - p.r - 20)
                        .attr('opacity',0.6)
                        .attr('font-size', "26px")
                        .text(cluster_headers[p.data.key])
                }
                else{

                    svg.append('text')
                        .attr('class', 'clusterheader')
                        .attr('x', p.x)
                        .attr('y', p.y - p.r)
                        .attr('opacity', 0.5)
                        .text(cluster_perc)
                }

                svg.append('circle')
                    .attr('cx', enclosingCircleAttr.x)
                    .attr('cy', enclosingCircleAttr.y)
                    .attr('r', enclosingCircleAttr.r)
                    .attr('fill', 'none')
                    .attr('stroke', '#000')
                    .attr('stroke-dasharray', 2)
                    .attr('stroke-opacity', 0.2)
                    .attr('class', 'enclosures')
                    .attr('id', 'enclose' + i)
            }
        });
        var temp = 0.5
        var maid_radius: any;
        if (similarity == 0 && frequency == 0 && recency == 0 && qscore == 0 && intent === 0){
            selectAll('.enclosures').remove()
            selectAll('.clusterheader').remove()
            selectAll('.clustertitle').remove()
            maid_radius = 5
        }
        else{
            maid_radius = 4
        }

        Object.keys(posi).forEach((k1: any) => {
            const k = posi[k1]
            var circleid = k[4]
            const tc = select(svgRef.current).select("#maid" + circleid)
                .attr('cx', function(){
                    return select(this).attr('cx')
                })
                .attr('cy', function(){
                    return select(this).attr('cy')
                })
                .transition()
                .duration(1000)
                .ease(easePoly)
                .delay(function() {
                    return temp * 1.25;
                })
                .attr('r', maid_radius)
                .attr('cx', function(){
                    if (similarity == 0 && frequency == 0 && recency == 0 && qscore == 0 && intent == 0){
                        return select(this).attr('data-original-x')
                    }
                    else{
                        return k[0]
                    }
                })
                .attr('cy', function(){
                    if (similarity == 0 && frequency == 0 && recency == 0 && qscore == 0 && intent == 0){
                        return select(this).attr('data-original-y')
                    }
                    else{
                        return k[1]
                    }
                })
            temp = temp + 0.5
        })

    }

    useEffect(() => {
        if (firstTime) {
            setFirstTime(false);
            return;
        }
        audience();
    }, [intent, qscore, similarity, frequency, recency]);


    return (
        <StyledProjection>
            <div>
                <h1>Who will you target?</h1>
                <div className="label-ctn">
                {labelData.map((label: TProjectionLabel, index: number) => (
                    <span key={index} style={{background: color_codes[label.cluster]}} className="label">{label.label}</span>
                ))}
                </div>
            </div>
            <svg className="audiencehall" width="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 900 500"
                 ref={svgRef}/>
        </StyledProjection>
    )

}

export default Projection;