import React, { useEffect, useState } from "react";
import StyledAudiencePlatforms from "./styled";
import fb_icon from "assets/platform_icons/meta.svg";
import x_icon from "assets/platform_icons/x-icon.svg";
import google_social_icon from "assets/platform_icons/adwords.svg";
import tiktok_icon from "assets/platform_icons/tiktok-icon.svg";
import connected_icon from "assets/platform_icons/connected-icon.svg";
import { useSelector } from "react-redux";
import {platform, home} from "services/selector";
import {IHeading} from 'interface/components';

import {
  getGoogleAuthUrl,
  getMetaAuthUrl,
  getXAuthUrl,
  FULLSTORY_ORG_ID,
  getTikTokAuthUrl,
} from "utils/axios_helper";
import FullStory from "react-fullstory";
import usePlatform from "../../../hooks/usePlatform";


const AudiencePlatforms: React.FunctionComponent<IHeading> = ({
  setHeading,
}) => {
  const {} = usePlatform("/dashboard/platform");
  const platformInfo = useSelector(platform);
  const {country} = useSelector(home);

  const [googleAuthUrl, setGoogleAuthUrl] = useState("");
  const [metaAuthUrl, setMetaAuthUrl] = useState("");
  const [xAuthUrl, setXAuthUrl] = useState("");
  const [tiktokAuthUrl, setTiktokAuthUrl] = useState("");

  useEffect(() => {
    setHeading(["Connect", country.charAt(0) + country.substring(1).toLowerCase()]);
    const load = async () => {
      const tmp = await getGoogleAuthUrl("/dashboard/platform");
      setGoogleAuthUrl(tmp);
      const temp1 = await getMetaAuthUrl("/dashboard/platform");
      setMetaAuthUrl(temp1);
      const temp2 = await getXAuthUrl("/dashboard/platform");
      setXAuthUrl(temp2.url);
      localStorage.setItem('xAuthSecret', temp2.secret);
      const temp3 = await getTikTokAuthUrl("/dashboard/platform");
      setTiktokAuthUrl(temp3);
    };
    load();
  }, []);

  return (
    <StyledAudiencePlatforms> 
      <div className="main-ctn">
        <div className="upper-ctn">
          <div className="connect-to-your">Connect to your Ad Platform</div>
          <div className="select-and-sign">Select and sign into your preffered ad platform</div>
        </div>

        <div className="middle-ctn">
        <div className="platform-ctn">
            <div>
              <div className="platform-icon-container">
                <img className="platform-icon" src={fb_icon}></img>
              </div>
            </div>
            <div className="platform-title">Facebook Ads <span className="asterisk1">&nbsp;*</span></div>
            <div>
              <div className="platform-connect-container">
                <a className="connect" href={metaAuthUrl}>
                  CONNECT
                </a>
              </div>
              <div className="connected-main-ctn">
                {platformInfo.meta.map((val, ind) => (
                    <div className="connected-container" key={ind}>
                      <div className="user-name">
                        @{val.name}
                      </div>
                      <div className="connected-ctn">
                        <div className="connected">CONNECTED</div>
                        <img src={connected_icon} className="connected-icon"></img>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="platform-ctn">
            <div>
              <div className="platform-icon-container">
                <img className="platform-icon" src={google_social_icon}></img>
              </div>
            </div>
            <div className="platform-title">Google Ads <span className="asterisk1">&nbsp;*</span></div>
            <div>
              <div className="platform-connect-container">
                <a className="connect" href={googleAuthUrl}>
                  CONNECT
                </a>
              </div>
              <div className="connected-main-ctn">
                {platformInfo.google.map((val, ind) => (
                    <div className="connected-container" key={ind}>
                      <div className="user-name">
                        @{val.name}
                      </div>
                      <div className="connected-ctn">
                        <div className="connected">CONNECTED</div>
                        <img
                          src={connected_icon}
                          className="connected-icon"
                        ></img>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="platform-ctn">
            <div>
              <div className="platform-icon-container">
                <img className="platform-icon" src={x_icon}></img>
              </div>
            </div>
            <div className="platform-title">Twitter(X) Ads <span className="asterisk1">&nbsp;*</span></div>
            <div>
              <div className="platform-connect-container">
                <a className="connect" href={xAuthUrl}>
                  CONNECT
                </a>
              </div>
              <div className="connected-main-ctn">
                {platformInfo.x.map((val, ind) => (
                    <div className="connected-container" key={ind}>
                      <div className="user-name">
                        @{val.name}
                      </div>
                      <div className="connected-ctn">
                        <div className="connected">CONNECTED</div>
                        <img
                          src={connected_icon}
                          className="connected-icon"
                        ></img>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="platform-ctn">
            <div className="platform-icon-container">
              <img className="platform-icon" src={tiktok_icon}></img>
            </div>
            <div className="platform-title">TikTok Ads <span className="asterisk1">&nbsp;*</span></div>
            <div>
              <div className="platform-connect-container">
                    <a className="connect" href={tiktokAuthUrl}>
                      CONNECT
                    </a>
              </div>
              <div className="connected-main-ctn">
                {platformInfo.tiktok.map((val, ind) => (
                    <div className="connected-container" key={ind}>
                      <div className="user-name">
                        @{val.name}
                      </div>
                      <div className="connected-ctn">
                        <div className="connected">CONNECTED</div>
                        <img
                          src={connected_icon}
                          className="connected-icon"
                        ></img>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>  
        </div>

        <div className="lower-ctn">
              <div className="when-you-provide">
              <span className="asterisk1"> * </span>
                When you provide consent through connecting your google account
                our platform will use it to obtain list of campaigns and then
                upload a user-list to your google ads platform and then attach it
                to the campaign of your choice.
              </div>
        </div>
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAudiencePlatforms>
  );
};

export default AudiencePlatforms;
