import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import {audience, home, platform} from "../services/selector";
import queryString from "query-string";
import {getMetaAccessToken, REACT_APP_BASE_URL, vaticAxiosGet, vaticAxiosPost} from "../utils/axios_helper";
import {set as platformSet} from "../services/reducer/platform";
import {set} from "../services/reducer/audience";
import {META_STATE} from "constants/environment";

const usePlatform = (endPoint: string) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {countryDb} = useSelector(home);

    const {
        platform_type,
        selected_google_ad_acc: selectedGoogleAdAcc,
        selected_meta_ad_acc: selectedMetaAdAcc,
        selected_x_ad_acc: selectedXAdAcc,
        selected_tiktok_ad_acc: selectedTiktokAdAcc,
    } = useSelector(audience);

    useEffect(() => {
        const currentUrlSearchParams = queryString.parse(location.search);

        const google_scope = Array.isArray(currentUrlSearchParams["scope"])
            ? currentUrlSearchParams["scope"][0]
            : currentUrlSearchParams["scope"];
        const code = Array.isArray(currentUrlSearchParams["code"])
            ? currentUrlSearchParams["code"][0]
            : currentUrlSearchParams["code"];
        const state = Array.isArray(currentUrlSearchParams["state"])
            ? currentUrlSearchParams["state"][0]
            : currentUrlSearchParams["state"];

        const oauthVerifier = Array.isArray(currentUrlSearchParams["oauth_verifier"])
            ? currentUrlSearchParams["oauth_verifier"][0]
            : currentUrlSearchParams["oauth_verifier"];

        const oauthToken = Array.isArray(currentUrlSearchParams["oauth_token"])
            ? currentUrlSearchParams["oauth_token"][0]
            : currentUrlSearchParams["oauth_token"];

        const auth_code = Array.isArray(currentUrlSearchParams["auth_code"])
            ? currentUrlSearchParams["auth_code"][0]
            : currentUrlSearchParams["auth_code"];

        if (google_scope && code) {
            const loadGoogleAccessTok = async () => {
                const res = await vaticAxiosGet("/api/v1/ads/google/refresh-token", {
                    code: code,
                    redirect_uri:
                        REACT_APP_BASE_URL + endPoint,
                    db: countryDb,
                    platform_type: platform_type,
                });
                const load = async () => {
                    const googleConnectedAccRes = await vaticAxiosGet(
                        "/api/v1/ads/google/connected-accounts"
                    );
                    if (googleConnectedAccRes && googleConnectedAccRes.data) {
                        dispatch(platformSet({ google: googleConnectedAccRes.data }));
                    }
                };
                if (res && res.statusCode === 200) load();
            };
            loadGoogleAccessTok();
        } else if (state === META_STATE) {
            const loadMetaAccessToken = async () => {
                await getMetaAccessToken(
                    code || "",
                    countryDb,
                    endPoint
                );
                const load = async () => {
                    const metaConnectedAccRes = await vaticAxiosGet(
                        "/api/v1/ads/meta/connected-accounts"
                    );
                    if (metaConnectedAccRes && metaConnectedAccRes.data) {
                        dispatch(platformSet({ meta: metaConnectedAccRes.data }));
                    }
                };
                load();
            };
            loadMetaAccessToken();
        } else if(oauthToken && oauthVerifier) {
            const loadXAccessToken = async () => {
                await vaticAxiosPost("/api/v1/ads/x/access-token", {
                    oauth_verifier: oauthVerifier,
                    oauth_token: oauthToken,
                    token_secret: localStorage.getItem('xAuthSecret') || '',
                    db: countryDb,
                });
                const load = async () => {
                    const xConnectedAccRes = await vaticAxiosGet(
                        "/api/v1/ads/x/connected-accounts"
                    );
                    if (xConnectedAccRes && xConnectedAccRes.data) {
                        dispatch(platformSet({ x: xConnectedAccRes.data }));
                    }
                };
                load();
            };
            loadXAccessToken();
        } else if(auth_code) {
            const loadTiktokAccessToken = async () => {
                await vaticAxiosPost("/api/v1/ads/tiktok/access-token", {
                    auth_code: auth_code,
                    code: code,
                    db: countryDb,
                });
                const load = async () => {
                    const tiktokConnectedAccRes = await vaticAxiosGet(
                        "/api/v1/ads/tiktok/connected-accounts"
                    );
                    if (tiktokConnectedAccRes && tiktokConnectedAccRes.data) {
                        dispatch(platformSet({ tiktok: tiktokConnectedAccRes.data }));
                    }
                };
                load();
            };
            loadTiktokAccessToken();
        }
    }, [location]);

    return { }
}

export default usePlatform;