import styled from "styled-components";
import CommonStyles from "../../APP/common";
import theme, {dimension, fontSize} from "../../APP/theme";
import colors from "../../APP/colors";


const StyledBilling = styled(CommonStyles)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
    
    .ctn {
        width: 1140px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    
    .header {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
    }

    .header-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
    
    .btn-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .btn-ctn {
        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: flex-start;
        width: auto;
    }
    
    .maid-count-ctn {
        display: flex;
        flex-direction: row;
        gap: 16px;
        padding: 16px;
        border-radius: 6px;
        background: ${colors.primary[50]};
        align-items: center;
    }
    
    .maid-count {
        font-size: ${fontSize.h2};
    }
    
    .mobile {
        display: none;
        background: ${colors.primary[200]};
    }
    
    .ctn-body {
        display: flex;
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    
    .item {
        padding: 24px;
        display: flex;
        flex-direction: column;
        height: 236px;
        width: 316px;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 5px;
        border: 1px solid ${colors.primary[300]};
    }
    
    .item-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    
    .item-h {
        font-size: ${fontSize.h1};
        font-family: ${theme.fontFamily};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .s-22 {
        font-size: ${fontSize.h4};
    }
    
    .size-22 {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    
    .ctn-body-media {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    
    .horizontal-line {
        width: 100%;
        height: 1px;
        background: #DFDFDF;
        margin: 20px 0;
    }
    
    .media-h {
        color: ${colors.primary[600]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h1};
        font-style: normal;
        font-weight: 700;
        line-height: ${theme.lineHeight};
    }
    
    .media-step {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .media-step-item {
        display: flex;
        flex-direction: column;
        gap: 13px;
        align-items: flex-start;
        max-width: 349px;
    }
    
    .step-btn {
        border-radius: 4px;
        background: ${colors.success[100]};
        padding: 10px;
        color: ${colors.black[500]};
        font-family: ${theme.fontFamily};
        font-weight: 400;
        line-height: ${theme.lineHeight};
        font-size: ${fontSize.h4};
        white-space: nowrap;
    }
    
    .media-step-text {
        font-size: ${fontSize.h5};
    }
    
    .disclaimer {
        display: flex;
        align-items: center;
    }
    
    .bg-y {
        padding: 10px;
        background: ${colors.warning[100]};
        border-radius: 4px;
        width: auto;
        height: auto;
        white-space: wrap;
    }
    
    .img-80 {
        width: 80px;
        height: 80px;
    }
    
    .w-700 {
        font-weight: 700;
    }
    
    .benefit-ctn {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    
    .benefit-item {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        border-radius: 5px;
        background: ${colors.primary[50]};
        width: 366px;
    }
    
    .benefit-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 119px;
    }
    
    table {
        border-collapse: collapse;
    }
    
    tr {
        height: 44px;
    }
    
    thead > tr {
        background: ${colors.primary[50] || "#EAF1FF"};
    }
    
    th, td {
        text-align: left;
        padding: 10px 16px;
        color: ${colors.black[500] || "#171717"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    tbody > tr {
        border-top: 1px solid #F9F9F9;
    }
    
    th {
        font-weight: 700;
    }
    
    .ready {
        padding: 8px 20px;
        border-radius: 4px;
        color: ${colors.success[700] || "#065C35"};
        background: ${colors.success[100] || "#CBF2C4"};
        text-align: center;
        font-size: ${fontSize.large};
    }

    .failed {
        padding: 8px 20px;
        border-radius: 4px;
        color: ${colors.danger[900] || "#5F1717"};
        background: ${colors.danger[100] || "#F6C1C1"};
        text-align: center;
        font-size: ${fontSize.large};
    }
    
    .ctn-body-billing {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    
    .radio-ctn {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
    
    .radio-row {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
    }
    
    .m-0  {
        margin: 0;
    }
    
    .free-btn {
        display: none;
    }
    
    .success-700 {
        color: ${colors.success[700]};
    }
    
    a {
        text-decoration: none;
    }

    .danger-700 {
        color: ${colors.danger[700]};
    }
    
    .table-mobile {
        display: none;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }
    
    .table-mobile-row {
        display: grid;
        grid-template-columns: 3fr 1fr;
        align-items: center;
    }
    
    .size-12 {
        font-size: ${fontSize.small};
    }
    
    .table-mobile-col-1 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
    }
    
    .table-mobile-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    
    .table-mobile-item-ctn {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .p-8 {
        padding: 8px;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .header-free-btn {
            display: none;
        }

        .ctn {
            width: 1120px;
        }
        
        .item-h {
            font-size: ${fontSize.h3}
        }
        
        .s-22 {
            font-size: ${fontSize.h5};
        }
        
        .media-h {
            font-size: ${fontSize.h3};
        }

        .step-btn {
            font-size: ${fontSize.h5};
        }

        .media-step-text {
            font-size: ${fontSize.h6};
        }

        .img-80 {
            width: 64px;
            height: 64px;
        }

        .benefit-item {
            height: 240px;
        }
        
        .free-btn {
            display: flex;
        }

        .maid-count {
            font-size: ${fontSize.h3};
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .header-free-btn {
            display: none;
        }

        .ctn {
            width: 615px;
        }

        .mobile {
            display: flex;
        }
        
        .desktop {
            display: none;
        }
        
        .btn-header {
            flex-wrap: wrap;
            gap: 24px;
        }
        
        .item {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: auto;
        }
        
        .media-h {
            font-size: ${fontSize.h1};
        }

        .media-step {
            flex-direction: column;
            gap: 32px;
        }

        .media-step-item {
            flex-direction: row;
            align-items: center;
            max-width: 100%;
        }

        .img-80 {
            width: 80px;
            height: 80px;
        }
        
        .benefit-ctn {
            width: 100%;
            flex-direction: column;
        }

        .benefit-item {
            height: auto;
            width: auto;
            flex-direction: row;
        }
        
        .benefit-text {
            height: 73px;
        }

        .maid-count {
            font-size: ${fontSize.h3};
        }
        
        table {
            display: none;
        }

        .table-mobile {
            display: flex;
        }
    }
`;

export default StyledBilling;
