import styled from "styled-components";

const StyledAudiencePlatforms = styled.div`
  margin-top: 40px;
  width: 100%;

  .container {
    display: flex;
    flex-direction:column;
    width: 100%;
  }

  .middle-ctn{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap:20px;
  }

  .container-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-left {
    display: flex;
    width: 540px;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
    margin-left: 25%;
  }
  .platforms {
    width: 100%;
    position: relative;
    font-size: 34px;
    line-height: 24px;
    font-weight: 700;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    color: #000;
    margin-top: 16px;
    margin-left: 4px;
  }

  .platforms-sub {
    width: 100%;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    color: #000;
    margin-top: 16px;
    margin-left: 6px;
  }

  .horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #cdd1d8;
  }

  .connect-and-choose {
    width: 480px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
  }

  .span {
    color: #82868f;
  }

  .select-platforms-1-container {
    margin-top: 24px;
    width: 152px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    color: #000;
  }

  .rectangle-div {
    max-width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cdd1d8;
    box-sizing: border-box;
    min-height: 320px;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .platform-title{
    font-size: 18px;
    line-height: 120%;
    color: #171717;
    text-align: center;
    font-weight:500;
  }

  .platform-ctn{
    display:flex;
    flex-direction:column;
    gap:40px;
    height:350px;
    max-width:300px;
    justify-content:center;
    border: 1px solid #cdd1d8;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius:10px;
  }

  .platform-icon-container{
    display:flex;
    justify-content:center;
    align-items:center;
  }

  .platform-icon {
    width: 64px;
    padding-right: 12px;
  }

  .platform-title{
    display:flex;
    align-items:center;
    justify-content:center;
  }

  .connected {
    width: 64px;
    position: relative;
    font-size: 10px;
    font-weight: 600;
    font-family: Inter;
    color: #09814a;
    text-align: left;
    display: inline-block;
  }

  .platform-connect-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .platform-container {
    display: flex;
  }

  .platform-name {
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    line-height: 2.5;
  }

  .user-name {
    //width: 68px;
    position: relative;
    font-size: 12px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }

  .connect {
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6.5px 12px;
    text-align: left;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
    font-weight: 600;
    height: 42px;
  }

  .continue-btn-container {
    position: relative;
    font-weight: 500;
    margin-top: 180px;
    cursor: pointer;
  }

  .continue-btn {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }

  .connected-ctn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .connected-icon {
    width: 10px;
    height: 10px;
    margin: 5px;
  }

  .flex-ver-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .platform-google-container {
    display: flex;
    justify-content: space-between;
    width:458px;
  }

  .w-100 {
    width: 458px;
  }

  .asterisk {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
  }

  .asterisk-text {
    margin-left: 8px;
    width: 500px;
    font-size: 12px;
    font-family: Inter;
    display: flex;
    gap: 5px;
    margin-top: 20px;
  }

  .platform-left-img {
    width: 400px;
  }

  .connected-container{
    display:flex;
    justify-content: space-between;
  }

  .connect-to-your {
    font-size: 48px;
    line-height: 120%;
    // font-weight: 500;
    color: #256ce8;
    text-align: left;
    margin-bottom: 24px;
  }

  .select-and-sign {
    width: 100%;
    font-size: 16px;
    line-height: 120%;
    color: #171717;
    text-align: left;
    display: inline-block;
    margin-bottom: 64px;
  }

  .when-you-provide {
    font-size: 16px;
    line-height: 120%;
    color: #171717;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top:40px;
  }

  .connected-main-ctn{
    margin-top:20px;
  }
`;

export default StyledAudiencePlatforms;
