import React, { useEffect, useRef } from 'react';
import {IBullsEye} from "../../../interface/components";



const BullsEye: React.FunctionComponent<IBullsEye> = ({ calibratorsData, width, height }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                const radius = canvas.width / 2;
                const ringWidth = radius / 5;
                const segmentDegrees = 72; // Each segment is 72 degrees
                const segmentColors = ['#B02026', '#EB8B25', '#F8CA20', '#88B947', '#3A9048'];
                const titles = ['1', '2', '3', '4', '5'];

                const drawRing = (startAngle: number, endAngle: number, color: string, ringIndex: number) => {
                    const outerRadius = radius - (ringWidth * ringIndex);
                    const innerRadius = radius - (ringWidth * (ringIndex + 1));
                    
                    ctx.beginPath();
                    ctx.arc(radius, radius, outerRadius, startAngle, endAngle);
                    ctx.arc(radius, radius, innerRadius, endAngle, startAngle, true);
                    ctx.closePath();
                    ctx.fillStyle = color;
                    ctx.fill();

                    if (height && height > 100) {
                        const ringCenterRadius = (outerRadius + innerRadius) / 2;
                        ctx.font = 'normal 10px Arial';
                        ctx.fillStyle = '#000';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillText(titles[ringIndex], radius, radius - ringCenterRadius);
                    }
                };

                const drawBullsEye = () => {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    calibratorsData.forEach((value, segmentIndex) => {
                        const startAngle = (segmentIndex * segmentDegrees) * (Math.PI / 180); // Convert degrees to radians
                        const endAngle = startAngle + segmentDegrees * (Math.PI / 180); // End angle of the segment

                        // Calculate how many rings should be filled
                        const filledRings = (0.5 - value) * 10;

                        for (let i = 0; i < 5; i++) {
                            if (i < filledRings) {
                                // Draw the ring
                                drawRing(startAngle, endAngle, segmentColors[4 - i], 4 - i);
                            }
                        }
                    });
                };

                drawBullsEye();
            }
        }
    }, [calibratorsData]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: `${height || 150}px`, width: `${width || 150}px` }}>
            <canvas ref={canvasRef} width={width || 150} height={height || 150}></canvas>
        </div>
    );
};

export default BullsEye;