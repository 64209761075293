import React, { useEffect, useState } from "react";
import StyledPlatform from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set } from "services/reducer/audience";
import fb_icon from "assets/platform_icons/facebook-icon.svg";
import google_social_icon from "assets/platform_google-1.svg";
import connected_icon from "assets/platform_icons/connected-icon.svg";
import x_icon from "assets/platform_x.svg";
import tiktok_icon from "assets/platform_icons/tiktok-icon.svg";
import {TGoogleAdsCred, TMetaAdsCred, TTiktokAdsCred, TXAdsCred} from "interface/types";
import {audience, home, platform} from "services/selector";
import usePlatform from "../../../../../hooks/usePlatform";
import {IHeading} from "interface/components";

const Platform: React.FunctionComponent<IHeading> = ({setHeading}) => {
  const {} = usePlatform('/dashboard/audience/create/platforms');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    metaAuthUrl,
    googleAuthUrl,
    xAuthUrl,
    tiktokAuthUrl,
    selected_google_ad_acc: selectedGoogleAdAcc,
    selected_meta_ad_acc: selectedMetaAdAcc,
    selected_x_ad_acc: selectedXAdAcc,
    selected_tiktok_ad_acc: selectedTiktokAdAcc,
  } = useSelector(audience);

  const {country} = useSelector(home);
  const {google, meta, x, tiktok} = useSelector(platform);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(set({ activeTab: 0 }));
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create", "Platform"]);
  }, []);

  const handleGoogleAdAccSel = (e: any) => {
    let temp = { ...selectedGoogleAdAcc };
    const val = parseInt(e.target.value);
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_google_ad_acc: temp }));
  };

  const handleMetaAdAccSel = (e: any) => {
    let temp = { ...selectedMetaAdAcc };
    const val = e.target.value;
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_meta_ad_acc: temp }));
  };

  const handleXAdAccSel = (e: any) => {
    let temp = { ...selectedXAdAcc };
    const val = e.target.value;
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_x_ad_acc: temp }));
  };

  const handleTiktokAdAccSel = (e: any) => {
    let temp = { ...selectedTiktokAdAcc };
    const val = e.target.value;
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_tiktok_ad_acc: temp }));
  };


  return (
    <StyledPlatform>
      <div className="container">
        <div className="platforms">Platforms</div>

        <div className="connect-and-choose">
          Connect and choose platform for your audience.
        </div>
        <div className="select-platforms-1-container">
          <span>Select platforms </span>
          <span className="span">(1)</span>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={fb_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Facebook Ads Account <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={metaAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {meta.map((val: TMetaAdsCred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleMetaAdAccSel}
                      checked={selectedMetaAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={google_social_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Google Ads Account <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={googleAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {google.map((val: TGoogleAdsCred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleGoogleAdAccSel}
                      checked={selectedGoogleAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={x_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Twitter(X) <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={xAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {x.map((val: TXAdsCred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleXAdAccSel}
                      checked={selectedXAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={tiktok_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Tik Tok <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={tiktokAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {tiktok.map((val: TTiktokAdsCred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleTiktokAdAccSel}
                      checked={selectedTiktokAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="asterisk-text">
          {" "}
          <span className="asterisk1"> * </span>
          <span>
            When you provide consent through connecting your google account our
            platform will use it to obtain list of campaigns and then upload a
            user-list to your google ads platform and then attach it to the
            campaign of your choice.
          </span>
        </div>
        <div className="subscript">
          <sup>1 </sup>
          If you don't have a google ad account, go open one and then return
          here to connect it.
        </div>

        {error && <div className="error-msg">{error}</div>}
        <div className="continue-btn-container">
          <div
            className="continue-btn"
            onClick={() => {
              if (
                google.filter(i => selectedGoogleAdAcc[i.id]).length +
                meta.filter(i => selectedMetaAdAcc[i.id]).length +
                x.filter(i => selectedXAdAcc[i.id]).length +
                tiktok.filter(i => selectedTiktokAdAcc[i.id]).length
                 === 0) {
                setError("You must connect atleast one Ad account");
                return;
              }
              navigate("/dashboard/audience/create/campaign");
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </StyledPlatform>
  );
};

export default Platform;
