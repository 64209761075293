import React from "react";
import StyledAudienceDetail from "./styled";
import emptyImg from "assets/campaign/empty.svg";
import { FULLSTORY_ORG_ID } from "utils/axios_helper";
import FullStory from "react-fullstory";
import BullsEye from "components/Chart/BullsEye";
import {IAudienceDetails} from "interface/components";
import useAudienceDetail from "hooks/useAudienceDetail";
import {useSelector} from "react-redux";
import {home} from "services/selector";
import Button from "shared/components/Button";

const AudienceDetail: React.FunctionComponent<IAudienceDetails> = ({
  setShowAudienceDetailsPage,
}) => {

    const {country} = useSelector(home);
  const {audienceData, progessText, progressPercent, platformIcon, platformAudienceStatus, handleAudienceClick, handleUploadAudience} = useAudienceDetail({setShowAudienceDetailsPage});

  return (
    <StyledAudienceDetail>
      <div className="header">
        <div className="heading">Your Calibrated Audience</div>
        <div className="heading-desc">
          Here’s the list of all audiences you’ve created. You can easily edit,
          delete, or calibrate them to fine-tune your campaigns.
        </div>
      </div>
      <div className="t-container">
      <div className="hor-line"></div>
        <div className="t-head">
          {/* <div className="t-head-font">Action</div> */}
          <div className="t-head-font">Name</div>
          <div className="t-head-font">Creation date</div>
          <div className="t-head-font">Country</div>
          <div className="t-head-font">Status</div>
          <div className="t-head-font">Platforms</div>
          <div className="t-head-font">Platform Status</div>
          <div className="t-head-font">Upload</div>
        </div>
        <div className="hor-line"></div>
        {audienceData.length > 0 && (
          <div className="t-body">
            {audienceData.map((val, ind) => (
              <div className={"t-row"} key={ind}>
                <div
                  className="t-data1"
                  onClick={() => {
                    handleAudienceClick(val.id);
                  }}
                >
                  <div>{val.audience_name} </div>
                  <BullsEye
                    calibratorsData={[
                      val.similarity,
                      val.intent,
                      val.recency,
                      val.frequency,
                      val.qscore,
                    ]}

                    height={20}
                    width={20}
                  />
                </div>
                <div className="t-data3">{val.created_at}</div>
                  <div className="t-data2">{country}</div>
                <div className="progress-ctn">
                  <div
                    className={
                      progressPercent[ind] === -1
                        ? "progress-text-error"
                        : "progress-text"
                    }
                  >
                    {`${parseInt(progessText[ind])}%`}
                  </div>
                  <div className="progress-bar-ctn">
                    <div className="progress-base"></div>
                    {progressPercent[ind] === -1 ? (
                      <div className="progress-error"></div>
                    ) : (
                      <div
                        className="progress"
                        style={{ width: `${progressPercent[ind]}%` }}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="t-data2">
                  {val.platforms.map((p, ind) => (
                    <img
                      src={platformIcon(p)}
                      key={ind}
                      className="platform-icon"
                    />
                  ))}
                </div>
                <div className="t-data2">
                  {platformAudienceStatus &&
                    (platformAudienceStatus as { [key: number]: any })[val.id]}
                </div>
                <Button text="Upload" action={() => {
                    handleUploadAudience(
                      val.id,
                      val.audience_name.toString(),
                      0
                    );
                  }}></Button>
              </div>
            ))}
          </div>
        )}

        {audienceData.length == 0 && (
          <div className="t-body-empty">
            <img src={emptyImg} alt="?" className="empty-img" />
            <div className="empty-h1">
              No Audience yet
            </div>
            <div className="empty-text">
              Create your first audience by clicking the button above.
            </div>
          </div>
        )}
      </div>

      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAudienceDetail>
  );
};

export default AudienceDetail;
