import React, { useEffect } from "react";
import StyledPaymentFail from "./styled";
import { useSelector } from "react-redux";
import { vaticAxiosPost } from "utils/axios_helper";
import queryString from "query-string";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {home}  from "services/selector";

interface IPaymentFail {
    setHeading: (val: string[]) => void;
}

const PaymentFail:React.FunctionComponent<IPaymentFail> = ({setHeading}) => {
    const {country} = useSelector(home);

    const updateInfo = async () => {
        const parsedHash = queryString.parse(window.location.search);
        const token = Array.isArray(parsedHash["token"])
        ? parsedHash["token"][0]
        : parsedHash["token"];
        if(!token) {
            return;
        }
        
        const response = await vaticAxiosPost('/api/v1/payment/status', {
            token: token,
            status: "failed",
        });

        if(response.statusCode === 200) {
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                });
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    }

    useEffect(() => {
        setHeading(["Billing", country.charAt(0) + country.substring(1).toLowerCase()]);
        updateInfo();
    }, []);
    return (
        <StyledPaymentFail>
            Fail
        </StyledPaymentFail>
    )
}

export default PaymentFail;