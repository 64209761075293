import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {home} from "../services/selector";
import {vaticAxiosGet} from "../utils/axios_helper";
import googlePlatformIcon from "../assets/platform_google-1.svg";
import facebookPlatformIcon from "../assets/platform_facebook.svg";
import xPlatformIcon from "../assets/platform_x.svg";
import snapPlatformIcon from "../assets/platform_snapchat.svg";
import tiktokPlatformIcon from "../assets/platform_tiktok.svg";
import {set as callibrateSet} from "../services/reducer/callibrate";
import {set} from "../services/reducer/audience";
import {TAudienceData} from "../interface/types";
import {IAudienceDetails} from "../interface/components";

const useAudienceDetail = ({setShowAudienceDetailsPage}: IAudienceDetails) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { countryDb } = useSelector(home);

    const [audienceData, setAudienceData] = useState<
        Array<TAudienceData>
    >([]);

    const [progessText, setProgressText] = useState<Array<string>>([]);
    const [progressPercent, setProgressPercent] = useState<Array<number>>([]);
    const [progressComplete, setProgressComplete] = useState<Array<boolean>>([]);

    let intervalId: NodeJS.Timeout | null = null;

    useEffect(() => {
        const load = async () => {
            const res = await vaticAxiosGet("/api/v1/ads/audiences", {
                country: countryDb,
            });
            if (res && res.data) {
                setAudienceData(res.data);

                const tempProgressPercent: number[] = [];
                const tempProgressText: string[] = [];
                const tempProgressComplete: boolean[] = [];

                for (let i = 0; i < res.data.length; i++) {
                    tempProgressPercent.push(15);
                    tempProgressText.push("15% in progress");
                    tempProgressComplete.push(false);
                }

                setProgressPercent(tempProgressPercent);
                setProgressText(tempProgressText);
                setProgressComplete(tempProgressComplete);

                const intervalMethod = async () => {
                    for (let i = 0; i < res.data.length; i++) {
                        if (tempProgressComplete[i] || tempProgressPercent[i] == 100) continue;

                        const progressRes = await vaticAxiosGet(
                            "/api/v1/get_generated_audience_progress",
                            { audience_id: res.data[i].id, db: countryDb }
                        );
                        const resData = progressRes.data;

                        if (!resData) continue;

                        const totalProgress = [
                            resData.maid_progress,
                            resData.qscore_progress,
                            resData.sim_progress,
                            resData.recency_progress,
                            resData.freq_progress,
                            resData.intent_progress,
                        ].reduce((a, b) => a + b, 0);

                        // sum all of them to get the progress percentage
                        setProgressPercent((prev) => {
                            let newArr = [...prev];
                            newArr[i] = totalProgress;
                            return newArr;
                        });
                        tempProgressPercent[i] = totalProgress;

                        setProgressText((prev) => {
                            let newArr = [...prev];
                            newArr[i] = `${totalProgress}% in progress`;
                            return newArr;
                        });

                        if (totalProgress === 100) tempProgressComplete[i] = true;
                    }
                };
                intervalMethod();
                intervalId = setInterval(intervalMethod, 20000);
            } else {
                setAudienceData([]);
            }
        };
        load();

        const cleanup = () => {
            if (intervalId) clearInterval(intervalId);
        };

        // Clear interval on unmount or page unload
        window.addEventListener("beforeunload", cleanup);
        return () => {
            cleanup();
            window.removeEventListener("beforeunload", cleanup);
        };
    }, [countryDb]);

    useEffect(() => {
        const totalStatus = progressComplete.reduce((a, b) => {
            return a && b;
        }, true);
        if (totalStatus && intervalId) {
            clearInterval(intervalId);
        }
    }, [progressComplete]);

    const platformIcon = (platform: string) => {
        if (platform === "google") {
            return googlePlatformIcon;
        } else if (platform === "meta") {
            return facebookPlatformIcon;
        } else if (platform === "x") {
            return xPlatformIcon;
        } else if (platform === "snap") {
            return snapPlatformIcon;
        } else if (platform === "tiktok") {
            return tiktokPlatformIcon;
        }
    };

    const [platformAudienceStatus, setPlatformAudienceStatus] = useState({});

    const getPlatformStatus = async (audience_id: number) => {
        const res = await vaticAxiosGet("/platform/google/audience-status", {
            audience_id: audience_id,
            db: countryDb,
        });
        setPlatformAudienceStatus((prev) => {
            return { ...prev, [audience_id]: res.data };
        });
    };

    const handleAudienceClick = (audience_id: number) => {
        const load = async () => {
            const res = await vaticAxiosGet("/api/v1/get_audience_info", {
                audience_id: audience_id,
                country: countryDb,
            });

            if (res && res.data) {
                const data = res.data;
                dispatch(
                    callibrateSet({
                        estimate_id: data.estimate_id,
                        qscore: data.qscore,
                        frequency: data.frequency,
                        similarity: data.similarity,
                        recency: data.recency,
                        is_vatic: data.is_vatic,
                        estimate_name: data.estimate_name,
                        intent: data.intent,
                    })
                );
                navigate('/dashboard/explore/calibrate', {
                    state: {id: data.estimate_id, isVatic: data.is_vatic, name: data.estimate_name,
                        qscore: parseFloat(data.qscore.toFixed(1)),
                        frequency: parseFloat(data.frequency.toFixed(1)),
                        similarity: parseFloat(data.similarity.toFixed(1)),
                        recency: parseFloat(data.recency.toFixed(1)),
                        intent: parseFloat(data.intent.toFixed(1)),
                    },
                });
            }
        };
        load();
    };

    const handleUploadAudience = (
        audience_id: number,
        name: string,
        size: number
    ) => {
        setShowAudienceDetailsPage(false);
        dispatch(
            set({ curr_audience_id: audience_id, aud_name: name, size: size })
        );
        navigate("/dashboard/audience/create");
    };

    return {audienceData, progessText, progressPercent, platformIcon, platformAudienceStatus, handleAudienceClick, handleUploadAudience}
}

export default useAudienceDetail;