import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {audience} from "../services/selector";
import {set} from "../services/reducer/audience";
import {TCampaign} from "../interface/types";
import {toast} from "react-toastify";
import {vaticAxiosGet} from "../utils/axios_helper";

const useCampaign = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);

    const {
        campaigns,
        selected_google_ad_acc: selectedGoogleAdAcc,
        selected_meta_ad_acc: selectedMetaAdAcc,
        selected_x_ad_acc: selectedXAdAcc,
        selected_tiktok_ad_acc: selectedTiktokAdAcc,
    } = useSelector(audience);

    const selectAllCampaigns = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const updatedCampaigns = campaigns
            .filter(
                (i) =>
                    selectedGoogleAdAcc[i.acc_id] ||
                    selectedMetaAdAcc[i.acc_id] ||
                    selectedXAdAcc[i.acc_id] ||
                    selectedTiktokAdAcc
            )
            .map((campaign) => ({
                ...campaign,
                checked: newSelectAll,
            }));
        dispatch(set({ campaigns: updatedCampaigns }));
    };

    const toggleCampaignSelection = (id: any) => {
        const updatedCampaigns = campaigns.map((campaign) => {
            if (campaign.id === id) {
                return { ...campaign, checked: !campaign.checked };
            }
            return campaign;
        });
        dispatch(set({ campaigns: updatedCampaigns }));
    };

    const handleNext = async () => {
        const selected_campaigns = campaigns.filter(
            (item:TCampaign) => item.checked
        );
        if (selected_campaigns.length === 0) {
            toast.error(`You must select atlease one campaign`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        navigate("/dashboard/audience/create/summary");
    };

    const fetchCampaigns = async () => {
        let temp: TCampaign[] = [];

        const fetchFromAPI = async (url: string, platform: string) => {
            const res = await vaticAxiosGet(url);
            if (res.data) {
                return res.data.map((i: any) => ({
                    checked: false,
                    name: i.name,
                    client: "Home Credit",
                    platforms: [platform],
                    id: i.id,
                    acc_id: i.acc_id,
                }));
            }
            return [];
        };

        const googleCampaigns = await fetchFromAPI(`/api/v1/ads/google/campaigns`, "google");
        const metaCampaigns = await fetchFromAPI(`/api/v1/ads/meta/campaigns`, "meta");
        const xCampaigns = await fetchFromAPI(`/api/v1/ads/x/campaigns`, "x");
        const tiktokCampaigns = await fetchFromAPI(`/api/v1/ads/tiktok/campaigns`, "tiktok");

        temp = [...googleCampaigns, ...metaCampaigns, ...xCampaigns, ...tiktokCampaigns];

        dispatch(set({ campaigns: temp })); // Store campaigns in Redux.
    };

    useEffect(() => {
        fetchCampaigns();
    }, []);

    return {selectAllCampaigns, toggleCampaignSelection, handleNext};
}

export default useCampaign;