import React, {useEffect, useState} from "react";
import StyledCalibrating from "./styled";
import {vaticAxiosGet, vaticAxiosPost} from "../../utils/axios_helper";
import {useSelector} from "react-redux";
import {home} from "../../services/selector";
import Header from "shared/components/Header";
import Button from "../../shared/components/Button";
import colors from "../../APP/colors";
import CompareBarChart from "../Chart/CompareBarChart";
import ToggleButton from "../../shared/components/ToggleButton";
import sortIcon from "assets/calibrate/Sort.png";
import BullsEyeNew from "../Chart/BullsEyeNew";
import {useLocation} from "react-router-dom";
import {IHeading} from "../../interface/components";
import {useNavigate} from "react-router";
import {TCalibrateGraphData, TProjectionData, TProjectionLabel} from "../../interface/types";
import Modal from "react-modal";
import './app.css';
import closeIcon from "../../assets/calibrate/Close_round.png";
import {toastPopup} from "../../utils/util";
import VerticalHistogram from "../Chart/VerticalHistogram";
import Projection from "../Chart/Projection";

const Calibrating: React.FunctionComponent<IHeading> = ({setHeading}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const id: number = location.state?.id || -1;
    const isVatic: boolean = location.state?.isVatic ?? true;
    const name: string = location.state?.name || '';

    const {countryDb} = useSelector(home);
    const [initData, setInitData] = useState<Array<TCalibrateGraphData>>([]);
    const [data, setData] = useState<Array<TCalibrateGraphData>>([]);
    const [audienceSize, setAudienceSize] = useState(0);
    const [urlCount, setUrlCount] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const [activeArc, setActiveArc] = useState<{[key: number]: number}>({
        0: 4 - 10 * (location.state.qscore || 0),
        1: 4 - 10 * (location.state.recency || 0),
        2: 4 - 10 * (location.state.frequency || 0),
        3: 4 - 10 * (location.state.intent || 0),
        4: 4 - 10 * (location.state.similarity || 0),
    });


    const [audienceName, setAudienceName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [filterDimension, setFilterDimension] = useState<{[id: number]: { selected: boolean; name: string }}>({});
    const [showData, setShowData] = useState<{[key: string]: number}>({});
    const [openShowModal, setOpenShowModal] = useState<boolean>(false);
    const [loadingShowData, setLoadingShowData] = useState(false);
    const [projectionMode, setProjectionMode] = useState(false);
    const [projectionData, setProjectionData] = useState<Array<TProjectionData>>([]);
    const [projectionLabel, setProjectionLabel] = useState<Array<TProjectionLabel>>([]);

    const getCalibrateData = async (qscore: number, frequency: number, recency: number, similarity: number, intent:number) => {
        const res = await vaticAxiosGet('/api/v1/calibrate', {
            is_vatic: isVatic,
            db: countryDb,
            id,
            qscore,
            frequency,
            recency,
            similarity,
            intent,
        });
        if(!res || !res.data) {
            toastPopup.error(res.message);
            return [];
        }
        setAudienceSize(res.audience_size);
        setUrlCount(res.url_hash_count);
        for (let i = 0; i < res.data.length; i++) {
            if (filterDimension[res.data[i].dim_id] === undefined)
                filterDimension[res.data[i].dim_id] = {selected: true, name: res.data[i].dim_name};
        }
        return res.data;
    };

    const handleGenerateAudience = async () => {
        if (!audienceName.trim()) {
            setNameError('Enter the audience name');
            return;
        }
        const qscore = (4 - activeArc[0]) / 10;
        const recency = (4 - activeArc[1]) / 10;
        const frequency = (4 - activeArc[2]) / 10;
        const intent = (4 - activeArc[3]) / 10;
        const similarity = (4 - activeArc[4]) / 10;
        const res = await vaticAxiosGet("/api/v1/generate-audience-service", {
            audience_name: audienceName,
            country: countryDb,
            estimate_id: id,
            is_internal: isVatic,
            qscore,
            frequency,
            recency,
            similarity,
            intent
        });
        vaticAxiosPost("/set-first-callibrate", {});
        setShowModal(false);
        navigate('/dashboard/audience');
    }

    const handleDimensionSelect = (id: number) => {
        setFilterDimension((p) => {
            return {
                ...p, [id]: {...p[id], selected: !p[id].selected}
            }
        });
    }

    const getShowData = async (type: string) => {
        setOpenShowModal(true);
        setLoadingShowData(true);
        const qscore = (4 - activeArc[0]) / 10;
        const recency = (4 - activeArc[1]) / 10;
        const frequency = (4 - activeArc[2]) / 10;
        const intent = (4 - activeArc[3]) / 10;
        const similarity = (4 - activeArc[4]) / 10;
        const res = await vaticAxiosGet("/api/v1/calibrate-show-data", {
            data_type: type,
            country: countryDb,
            id: id,
            is_vatic: isVatic,
            qscore,
            frequency,
            recency,
            similarity,
            intent
        });
        if (res && res.data) {
            setShowData(res.data);
        } else {
            toastPopup.error(res.message);
            setShowData({});
        }
        setLoadingShowData(false);
    }

    useEffect(() => {
        setHeading(["Calibrate"]);

        (async function()  {
            const res = await getCalibrateData(0, 0, 0, 0, 0);
            const temp: TCalibrateGraphData[] = res.map((item: any) => {
                return {dimensionId: item.dim_id, dimensionName: item.graph_label || item.dim_name,
                    absoluteValue: item.absolute_difference, relativeValue: item.relative_difference}});
            setInitData(temp);
            const temp1 = temp.map((item: TCalibrateGraphData) => {return {
                ...item, absoluteValue: 0, relativeValue: 0
            }});
            setData(temp1);
        }());

        (async function() {
            const res = await vaticAxiosGet('/api/v1/calibrate-projection', {
                id: id,
                is_vatic: isVatic,
                db: countryDb,
            });
            if(res) {
                setProjectionData(res.data);
                setProjectionLabel(res.label_data);
            }
        })();
    }, []);

    useEffect(() => {
        if (projectionMode)
            return;
        (async function()  {
            const qscore = (4 - activeArc[0]) / 10;
            const recency = (4 - activeArc[1]) / 10;
            const frequency = (4 - activeArc[2]) / 10;
            const intent = (4 - activeArc[3]) / 10;
            const similarity = (4 - activeArc[4]) / 10;
            const res = await getCalibrateData(qscore, frequency, recency, similarity, intent);
            const temp: TCalibrateGraphData[] = res.map((item: any) => {
                return {dimensionId: item.dim_id, dimensionName: item.graph_label || item.dim_name,
                    absoluteValue: item.absolute_difference, relativeValue: item.relative_difference}});
            if(similarity === 0 && recency === 0 && frequency === 0 && qscore === 0 && intent === 0) {
                const temp1 = initData.map((item: TCalibrateGraphData) => {return {
                    ...item, absoluteValue: 0, relativeValue: 0
                }});
                setData(temp1);
                setInitData(temp);
                return;
            }
            setData(temp);
        }());
    }, [activeArc]);

    return (
        <StyledCalibrating>
            <Header text="Cancel & Go Back" action={() => {navigate('/dashboard/explore')}} />
            <div className="heading-ctn">
                <div className="heading">Calibrating: {name}</div>
                <Button text={"Change Audience"} borderRadius={"6px"} action={() => {navigate('/dashboard/explore', {state: {audienceType: isVatic ? 2: 1}})}} background={`${colors.white[50] || "#FFF"}`} border = {`1px solid ${colors.primary[600]  || "#256CE8"}`} color={colors.primary[600]}/>
            </div>
            <div className="count-ctn">
                <div className="row">
                    <span>Audience Size</span>
                    <span className="label">{audienceSize?.toLocaleString()}</span>
                </div>

                <div className="row">
                    <span>URLs Count</span>
                    <span className="label">{urlCount?.toLocaleString()}</span>
                </div>
            </div>

            <div className="body-ctn">
                <div className="graph-ctn">
                    {projectionMode &&
                        <Projection qscore = {(4 - activeArc[0]) / 10}
                         recency = {(4 - activeArc[1]) / 10}
                         frequency = {(4 - activeArc[2]) / 10}
                         intent = {(4 - activeArc[3]) / 10}
                         similarity = {(4 - activeArc[4]) / 10}
                         data={projectionData}
                         labelData={projectionLabel}
                        />
                    }
                    {!projectionMode && <div className="graph-ctn-head">
                        <div className="legend-ctn">
                            <div className="legend-ctn-child">
                                <span className="init-legend"/>
                                <span className="label-14">Initial Value</span>
                            </div>
                            <div className="legend-ctn-child">
                                <span className="current-legend"/>
                                <span className="label-14">Current Value</span>
                            </div>
                        </div>

                        {!showOptions && <div className="option-ctn" onClick={() => setShowOptions(true)}>
                            <img src={sortIcon} alt="sort" className="icon-img"/>
                            <span className="label-14 options-text">Options</span>
                        </div>}
                    </div> }
                    {!projectionMode &&
                    <div className="graph-ctn-body-relative">
                        {
                            showOptions && (
                                <div className="graph-ctn-options">
                                    <div className="close-menu">
                                        <div className="label-14 font-18">Options</div>
                                        <img src={closeIcon} alt="X" className="icon-img cursor-pointer"
                                             onClick={() => setShowOptions(false)}/>
                                    </div>
                                    <div className="dimension-ctn">
                                        {
                                            Object.keys(filterDimension).map((item, index) => (
                                                <div key={"dimension" + index} className="dimension-row">
                                                    <input type="checkbox" className="checkbox"
                                                           checked={filterDimension[parseInt(item)].selected}
                                                           onChange={() => handleDimensionSelect(parseInt(item))}/>
                                                    <span className="label-14">
                                                        {filterDimension[parseInt(item)].name}
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }

                        <div className={showOptions ? "graph-ctn-body  blurred": "graph-ctn-body"}>

                            <CompareBarChart label={"Absolute Difference"}
                                             labels={initData.map(item => {
                                                 return {id: item.dimensionId, value: item.dimensionName}
                                             })}
                                             initVal={initData.map(item => {
                                                 return {id: item.dimensionId, value: item.absoluteValue}
                                             })}
                                             currVal={data.map(item => {
                                                 return {id: item.dimensionId, value: item.absoluteValue}
                                             })}
                                             filter={Object.keys(filterDimension).filter(item =>
                                                 filterDimension[parseInt(item)].selected).map(item => parseInt(item))}/>

                            <CompareBarChart label={"Relative Difference"}
                                             labels={initData.map(item => {
                                                 return {id: item.dimensionId, value: item.dimensionName}
                                             })}
                                             initVal={initData.map(item => {
                                                 return {id: item.dimensionId, value: item.relativeValue}
                                             })}
                                             currVal={data.map(item => {
                                                 return {id: item.dimensionId, value: item.relativeValue}
                                             })}
                                             filter={Object.keys(filterDimension).filter(item =>
                                                 filterDimension[parseInt(item)].selected).map(item => parseInt(item))}/>
                        </div>
                    </div> }

                    <div className="graph-ctn-footer">
                        <span className="label-14">Graph Mode</span>
                        <ToggleButton defaultChecked={false} action={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setProjectionMode(p => !p)
                        }}/>
                        <span className="label-14">Projection Mode</span>
                    </div>
                </div>

                <div className="bulls-eye-ctn">
                    <BullsEyeNew activeArc={activeArc} setActiveArc={setActiveArc} getShowData={getShowData}/>
                </div>
            </div>

            <div className="audience-btn">
                <Button text={'Save Audience'} action={() => {
                    setShowModal(true)
                }}/>
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                className="modal-calibrate"
                overlayClassName="modal-overlay-calibrate"
                style={{
                    content: {
                        top: `50%`,
                        left: `50%`,
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)'
                    },
                }}
            >
                <div className="modal-content-calibrate">
                    <div className="calibrate-heading-ctn">
                        <div className="calibrate-heading">Save Audience</div>
                        <img src={closeIcon} alt="X" className="calibrate-icon" onClick={() => setShowModal(false)}/>
                    </div>

                    <div className="calibrate-input-ctn">
                        <div className='calibrate-label'>Name of the Audience</div>
                        <input type='text' className='calibrate-input' placeholder='Audience Name'
                               value={audienceName}
                               onChange={(e) => {
                                   setAudienceName(e.target.value);
                                   setNameError('')
                               }}/>
                        {nameError && <div className="name-error">{nameError}</div>}
                    </div>

                    <div className="calibrate-footer">
                        <Button text={"Cancel"} action={() => setShowModal(false)}
                                border={`1px solid ${colors.primary[600] || "#256CE8"}`}
                                background={`${colors.white[50] || '#FFF'}`}
                                color={colors.primary[600]}
                        />

                        <Button text={'Save this Audience'} action={handleGenerateAudience}/>
                    </div>

                </div>
            </Modal>

            <Modal
                isOpen={openShowModal}
                onRequestClose={() => setOpenShowModal(false)}
                style={{
                    content: {
                        width: 'auto',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Centers the modal vertically
                        border: 'none',
                        padding: '0px',
                        overflow: 'auto',
                        height: 'auto'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Background color of the overlay
                    }
                }}
            >
                <div>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <span style={{padding: "5px 10px", background: "cyan", cursor: "pointer"}} onClick={() => setOpenShowModal(false)}>Close</span>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap"}}>
                        {loadingShowData ? (<div>Loading...</div>):
                            <VerticalHistogram data={Object.keys(showData).map((timeRange) => { return {label: timeRange, value: showData[timeRange]}}) }/>
                        }
                    </div>
                </div>
            </Modal>
        </StyledCalibrating>
    )
}

export default Calibrating;