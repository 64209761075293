import React, { useEffect } from "react";
import StyledCreateAudience from "./styled";
import { Route, Routes } from "react-router";
import Left from "./Left";
import Platform from "./Platform";
import Campaign from "./Campaign";
import Summary from "./Summary";
import {
  getGoogleAuthUrl,
  getMetaAuthUrl,
  getTikTokAuthUrl,
  getXAuthUrl,
} from "utils/axios_helper";
import { useDispatch, useSelector } from "react-redux";
import {set,} from "services/reducer/audience";
import {platform, home} from "services/selector";

interface CreateAudienceProps {
  setHeading: (val: String[]) => void;
}

const CreateAudience: React.FunctionComponent<CreateAudienceProps> = ({
  setHeading,
}) => {
  const dispatch = useDispatch();
  const platformInfo = useSelector(platform);
  const {country} = useSelector(home);

  useEffect(() => {
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create"]);
    const load = async () => {
      const tmp = await getGoogleAuthUrl(
        "/dashboard/audience/create/platforms"
      );
      dispatch(set({ googleAuthUrl: tmp }));
      const temp1 = await getMetaAuthUrl(
        "/dashboard/audience/create/platforms"
      );
      dispatch(set({ metaAuthUrl: temp1 }));

      const temp2 = await getXAuthUrl("/dashboard/platform");
      dispatch(set({ xAuthUrl: temp2.url }));
      localStorage.setItem('xAuthSecret', temp2.secret);

      const temp3 = await getTikTokAuthUrl("/dashboard/platform");
      dispatch(set({ tiktokAuthUrl: temp3 }));
    };
    load();
  }, []);

  return (
    <StyledCreateAudience>
      <div className="container">
        <Left></Left>
        <div>
          <Routes>
            <Route path="*" element={<Platform  setHeading={setHeading}/>} />
            <Route path="campaign" element={<Campaign />} />
            <Route path="summary" element={<Summary  setHeading={setHeading}/>} />
          </Routes>
        </div>
      </div>
    </StyledCreateAudience>
  );
};

export default CreateAudience;
