import React, {useEffect, useState} from "react";
import StyledHomeReports from "./styled";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import {Line} from "react-chartjs-2";
import axios from "axios";
import {FULLSTORY_ORG_ID, vaticAxiosGet} from "utils/axios_helper";
import FullStory from "react-fullstory";
import {useDispatch, useSelector} from "react-redux";
import {set} from "../../../services/reducer/home";
import {home} from "services/selector";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface LinearChartProps {
  labels: string[];
  impressionsData: number[];
  clicksData: number[];
}

const LineChartComponent: React.FunctionComponent<LinearChartProps> = ({
  labels,
  impressionsData,
  clicksData,
}) => {
  const [chartData, setChartData] = useState({
    // labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], // You can fetch this too if it changes
    labels: labels,
    datasets: [
      {
        label: "Impressions",
        data: impressionsData, // Initial empty data
        borderColor: "#4485F4",
        backgroundColor: "#4485F4",
        fill: false,
      },
      {
        label: "Clicks",
        data: clicksData, // Initial empty data
        borderColor: "#46B884",
        backgroundColor: "#46B884",
        fill: false,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          stepSize: 500,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.example.com/data");
        const { impressions, clicks } = response.data;

        setChartData((prevData) => ({
          ...prevData,
          datasets: [
            { ...prevData.datasets[0], data: impressions },
            { ...prevData.datasets[1], data: clicks },
          ],
        }));
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    // fetchData();
  }, []);

  useEffect(() => {
    setChartData({
      labels: labels,
      datasets: [
        {
          label: "Impressions",
          data: impressionsData, // Initial empty data
          borderColor: "#4485F4",
          backgroundColor: "#4485F4",
          fill: false,
        },
        {
          label: "Clicks",
          data: clicksData, // Initial empty data
          borderColor: "#46B884",
          backgroundColor: "#46B884",
          fill: false,
        },
      ],
    });
  }, [labels, impressionsData, clicksData]);

  return <Line className="line-chart" data={chartData} options={options} />;
};

interface CampaignData  {
  "campaign_name": string;
  "campaign_id": number | string;
  "clicks": number | string;
  "impressions": number | string;
  campaign_goal: number | string;
  "date": string;
}

interface AdsetData {
    "adset_id": number | string;
    "adset_name": string;
    "date": string;
    "impressions": string | number;
    "clicks": string | number;
    "campaign_goal": string | number;
}

interface HomeReportsProps {
  setHeading: (val: String[]) => void;
}

const HomeReports: React.FunctionComponent<HomeReportsProps> = ({
  setHeading,
}) => {
  const prevData = useSelector(home)
  const dispatch = useDispatch();

  const [show_campaign, set_show_campaign] = useState<boolean>(true);
  const [impressions, setImpressions] = useState<Array<number>>([]);
  const [clicks, setClicks] = useState<Array<number>>([]);
  const label1 = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const label2: string[] = [];
  for (let i = 0; i <= 30; i++) {
    label2.push(i.toString());
  }
  const [label, setLabel] = useState<Array<string>>(label1);
  const [day, setDay] = useState(7);

  const [campaignsData, setCampaignsData] = useState<Array<CampaignData>>(prevData.campaignsClick);
  const [adSetsData, setAdSetsData] = useState<Array<AdsetData>>(prevData.adSetsClick);

  const [filteredCampaignsData, setFilteredCampaignsData] = useState<Array<CampaignData>>([]);
  const [filteredAdSetsData, setFilteredAdSetsData] = useState<Array<AdsetData>>([]);

  const filterData = (initData: any[], n: number) => {
    return initData.filter(
        (item: any) => {
          const today = new Date();
          const itemDate = new Date((item.date).replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1"));
          const daysAgo = new Date(
              today.getTime() - n * 24 * 60 * 60 * 1000
          );
          return itemDate >= daysAgo && itemDate <= today;
        }
    );
  }

  const calculateImpClk = (initData: any[]) => {
    // // Calculate total sum of impressions and clicks mapped by date
    const totals = initData.reduce(
      (accumulator: any, item: any) => {
        const date = item.date;
        accumulator[date] = accumulator[date] || {
          impression: 0,
          click: 0,
        };
        accumulator[date].impression += item.impressions;
        accumulator[date].click += item.clicks;
        return accumulator;
      },
      {}
    );

    const tmpLabel = [];
    const tmpClicks = [];
    const tmpImpressions = [];

    for(const key in totals) {
      tmpLabel.push(key);
      tmpClicks.push(totals[key]['click']);
      tmpImpressions.push(totals[key]['impression']);
    }

    setLabel(tmpLabel);
    setClicks(tmpClicks);
    setImpressions(tmpImpressions);

  }

  useEffect(() => {
    setHeading(["Report", prevData.country.charAt(0) + prevData.country.substring(1).toLowerCase()]);

    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/ads/campaign-click-impression", {days: day});
      if (res && res.data) {
        setCampaignsData(res.data);
        setFilteredCampaignsData(filterData(res.data, day));
        dispatch(set({campaignsClick: res.data}));
        calculateImpClk(res.data);
      }

      const res1 = await vaticAxiosGet("/api/v1/ads/adset-click-impression", {days: day});
      if (res1 && res1.data) {
        setAdSetsData(res1.data);
        setFilteredAdSetsData(filterData(res1.data, day));
        dispatch(set({adSetsClick: res1.data}));
        calculateImpClk(res1.data);
      }
    };
    load();
  }, []);

  const handleDateChange = (n: number) => {
    setDay(n);
    setFilteredAdSetsData(filterData(adSetsData, n));
    setFilteredCampaignsData(filterData(campaignsData, n));
  }

  useEffect(() => {
    calculateImpClk(show_campaign? filteredCampaignsData: filteredAdSetsData);
  }, [filteredAdSetsData, filteredCampaignsData, show_campaign]);

  return (
      <StyledHomeReports>
        <div className="options-container">
          <div style={{display: "flex", gap: "32px"}}>
            <div>
            <span
                className={`option-tab ${
                    show_campaign ? "selected-option-tab" : ""
                }`}
                onClick={() => {
                  set_show_campaign(true);
                }}
            >
              Campaigns
            </span>
            </div>

            <div>
            <span
                className={`option-tab ${
                    show_campaign ? "" : "selected-option-tab"
                }`}
                onClick={() => {
                  set_show_campaign(false);
                }}
            >
              Ad Sets
            </span>
            </div>
          </div>
          <div className="day-dropdown-container">
            <div>
              <select onChange={(e) => handleDateChange(parseInt(e.target.value))}>
                <option className="days" value={7}>
                  7 Days
                </option>
                <option className="days" value={30}>
                  1 Month
                </option>
              </select>
            </div>

            <div>
              <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Icon">
                  <path
                      id="Vector"
                      d="M10.7966 6.09743C11.0189 6.26119 11.0664 6.57418 10.9027 6.79653C10.7755 6.96923 10.6483 7.1334 10.5367 7.2764C10.3139 7.5618 10.0073 7.9441 9.67407 8.3278C9.34307 8.70905 8.97602 9.1032 8.63342 9.40595C8.46267 9.5568 8.28617 9.69555 8.11407 9.7993C7.95572 9.89475 7.73812 10 7.50007 10C7.26202 10 7.04437 9.89475 6.88602 9.7993C6.71392 9.69555 6.53747 9.5568 6.36672 9.40595C6.02408 9.1032 5.65706 8.70905 5.32604 8.3278C4.99286 7.9441 4.6862 7.5618 4.46344 7.2764C4.35186 7.1334 4.22463 6.96924 4.09744 6.79654C3.93368 6.57419 3.98118 6.26119 4.20352 6.09743C4.2929 6.0316 4.39693 5.99992 4.50003 6H7.50007H10.5001C10.6032 5.99992 10.7072 6.0316 10.7966 6.09743Z"
                      fill="black"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        {show_campaign ? (
            <div>
              <LineChartComponent
                  labels={label}
                  impressionsData={impressions}
                  clicksData={clicks}
              ></LineChartComponent>
              <div className="t-container">
                <div className="t-head">
                  <div className="t-head-font">Campaign</div>
                  <div className="t-head-font">Impressions</div>
                  <div className="t-head-font">Clicks</div>
                  <div className="t-head-font">Goal</div>
                </div>
                <div className="hor-line"></div>
                {
                  <div className="t-body">
                    {filteredCampaignsData.map(
                        (val, ind) =>
                            (
                                <div
                                    className={ind % 2 === 0 ? "t-row-odd" : "t-row"}
                                    key={ind}
                                >
                                  <div className="t-data1">{val.campaign_name}</div>
                                  <div className="t-data3">{val.impressions}</div>
                                  <div className="t-data2">{val.clicks}</div>
                                  <div className="t-data2">{val.campaign_goal}</div>
                                </div>
                            )
                    )}
                  </div>
                }
              </div>
            </div>
        ) : (
            <div>
              <LineChartComponent
                  labels={label}
                  impressionsData={impressions}
                  clicksData={clicks}
              ></LineChartComponent>
              <div className="t-container">
                <div className="t-head">
                  <div className="t-head-font">AdSet</div>
                  <div className="t-head-font">Impressions</div>
                  <div className="t-head-font">Clicks</div>
                  <div className="t-head-font">Goal</div>
                </div>
                <div className="hor-line"></div>
                {
                  <div className="t-body">
                    {filteredAdSetsData.map(
                        (val, ind) =>
                             (
                                <div
                                    className={ind % 2 === 0 ? "t-row-odd" : "t-row"}
                                    key={ind}
                                >
                                  <div className="t-data1">{val.adset_name}</div>
                                  <div className="t-data3">{val.impressions}</div>
                                  <div className="t-data2">{val.clicks}</div>
                                  <div className="t-data2">{val.campaign_goal}</div>
                                </div>
                            )
                    )}
                  </div>
                }
              </div>
            </div>
        )}
        <div>
          <FullStory org={FULLSTORY_ORG_ID || ''}/>
        </div>
      </StyledHomeReports>
  );
};

export default HomeReports;
