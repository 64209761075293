import React, { useEffect, useState } from "react";
import StyledSummary from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set, unset } from "services/reducer/audience";
import { vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_APP_MEASUREMENT_ID } from "utils/axios_helper";
import ReactGA from "react-ga4";
import {audience, home } from "services/selector";

interface Props {
  setHeading: (val: String[]) => void;
}

const Summary: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  ReactGA.initialize(REACT_APP_MEASUREMENT_ID || "");

  const {
    aud_name: audienceName,
    awareness ,
    promoting: promotions,
    t_audience: targetAud,
    t_region: advertise_location,
    method,
    custom_keywords,
    curr_audience_id: audience_id,
    keywords,
    urls,
    campaigns,
    size: audienceSize,
} = useSelector(audience);
  const {countryDb, country} = useSelector(home);
  const [goal, setGoal] = useState("Awareness");

  useEffect(() => {
    setGoal(awareness ? "Awareness" : "conversion");
  }, [awareness]);

  const handleClose = async () => {
    const selected_campaigns = campaigns.filter(
      (item) => item.checked
    );

    const temp: any[] = selected_campaigns.map((item) => {
      return {
        campaign_id: item.id.toString(),
        platform: item.platforms[0],
        name: item.name,
        acc_id: item.acc_id.toString(),
      };
    });

    const res = await vaticAxiosPost("/api/v1/ads/populate/audience", {
      db: countryDb,
      audience_id: audience_id,
      campaigns: temp,
      audience_name: audienceName
    });

    if (res && res.statusCode == 200) {
      toast.success("Successfully Created Audience");
      dispatch(unset());
      navigate("/dashboard/audience");
    } else {
      toast.error(res.message);
    }

    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Audience Created",
    });
  };

  useEffect(() => {
    dispatch(set({ activeTab: 2 }));
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create", "Summary"]);
  }, []);

  return (
    <StyledSummary>
      <div>
        <div className="t4">Audience Name</div>
        <div className="t5">{audienceName}</div>
      </div>
      <div className="hor-line-neg"></div>
      {method === 1 ? (
        <div className="p-div">
          <div>
            <div className="t4">Audience Goal</div>
            <div className="t5">{goal}</div>
          </div>

          <div className="hor-line-neg"></div>
          <div>
            <div className="t4">Promotion</div>
            <div className="t5">{promotions}</div>
          </div>

          <div className="hor-line-neg"></div>
          <div>
            <div className="t4">Targeted Audience</div>
            <div className="t5">{targetAud}</div>
          </div>
        </div>
      ) : (
        <>
          {method === 2 ? (
            <div className="p-div">
              <div className="t4">Expert Keywords</div>
              <div className="t5">{custom_keywords}</div>
            </div>
          ) : (
            <div className="">
              <div className="t4">Urls</div>
              <div className="t5">
                {urls.split("\n").map((val, ind) => (
                  <div>{val}</div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <div className="hor-line-neg"></div>
      {/* <div>
        <div className="t4">Targeted Location</div>
        <div className="t5">
          {audience.advertise_location.charAt(0).toUpperCase() +
            audience.advertise_location.slice(1).toLocaleLowerCase()}
        </div>
      </div> */}
      {/* <div className="hor-line-neg"></div> */}
      <div>
        <div className="t4">Audience Size</div>
        {audienceSize.toLocaleString()}
      </div>
      <div className="hor-line-neg"></div>
      <div className="campaign-row">
        <div className="campaign-head">Campaign</div>
        <div className="campaign-head">Platform</div>
      </div>
      {campaigns.map(
        (campaign: any, ind: number) =>
          campaign.checked && (
            <div className="campaign-row">
              <div className="campaign-name">{campaign.name}</div>
              <div className="campaign-platform">{campaign.platforms}</div>
            </div>
          )
      )}
      <div className="continue-btn-container">
        <div
          className="back-btn"
          onClick={() => {
            navigate("/dashboard/audience/create/campaign");
          }}
        >
          Back
        </div>
        <div className="continue-btn" onClick={handleClose}>
          Finish
        </div>
      </div>
    </StyledSummary>
  );
};

export default Summary;
